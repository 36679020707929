import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, serverTimestamp, updateDoc } from '@firebase/firestore'
import { clientInquiryConverter, ClientInquiryReplyConverter, ClientInquiryReplyInput } from '@maru44/huntre-utils/src/models/clientInquiry'
import { useCallback } from 'react'
import { NotFoundError, ValidationError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'

export const useClientInquiry = (clientId: string, inquiryId: string) => {
  const getInquiry = useCallback(async () => {
    const ref = doc(firestore, `clients/${clientId}/clientInquiries/${inquiryId}`).withConverter(clientInquiryConverter)
    const snap = await getDoc(ref)

    if (!snap.exists() || !snap.data()) {
      throw new NotFoundError('clientInquiry', inquiryId)
    }
    return snap.data()
  }, [clientId, inquiryId])

  const updateInquiryResolved = useCallback(
    async (resolve: boolean) => {
      const ref = doc(firestore, `clients/${clientId}/clientInquiries/${inquiryId}`).withConverter(clientInquiryConverter)
      const snap = await getDoc(ref)

      if (!snap.exists() || !snap.data()) {
        throw new NotFoundError('clientInquiry', inquiryId)
      }
      if (!!snap.data().resolvedAt) {
        throw new ValidationError(`already resolved: ${inquiryId}`)
      }

      await updateDoc(ref, {
        resolvedAt: resolve ? serverTimestamp() : null,
      })
    },
    [clientId, inquiryId]
  )

  const listClientInquiryReplies = useCallback(async () => {
    const q = query(
      collection(firestore, `clients/${clientId}/clientInquiries/${inquiryId}/clientInquiryReplies`).withConverter(ClientInquiryReplyConverter),
      orderBy('createdAt', 'asc')
    )
    const refs = await getDocs(q)

    return refs.docs.map((v) => v.data())
  }, [clientId, inquiryId])

  const createClientInquiryReply = useCallback(
    async (input: ClientInquiryReplyInput) => {
      const ref = collection(firestore, `clients/${clientId}/clientInquiries/${inquiryId}/clientInquiryReplies`)

      const now = serverTimestamp()
      await addDoc(ref, {
        ...input,
        createdAt: now,
        updatedAt: now,
      })
    },
    [clientId, inquiryId]
  )

  const deleteClientInquiryReply = useCallback(
    async (id: string) => {
      const ref = doc(firestore, `clients/${clientId}/clientInquiries/${inquiryId}/clientInquiryReplies/${id}`)
      await deleteDoc(ref)
    },
    [clientId, inquiryId]
  )

  return { getInquiry, updateInquiryResolved, listClientInquiryReplies, createClientInquiryReply, deleteClientInquiryReply }
}
