import { ClientDeviceMemo } from '@maru44/huntre-utils/src/models/clientDeviceMemo'
import { ClientMember } from '@maru44/huntre-utils/src/models/clientMember'
import { Avatar, Box, Button, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import { useCallback, useContext, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { IoTrashSharp } from 'react-icons/io5'
import { BaseDialog } from 'src/components/atoms/BaseDialog'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { baseURL } from 'src/config'
import { useClientDeviceMemos } from 'src/hooks/clientDeviceMemo/useClientDeviceMemos'
import { formatTimestamp } from 'src/utils/firebase'
import { capture } from 'src/utils/sentry'
import useSWR from 'swr'

type Props = {
  members: ClientMember[]
  clientId: string
  deviceId: string
}

export const Memos = ({ clientId, deviceId, members }: Props) => {
  const [user] = useContext(AuthContext)
  const { listDeviceMemos, createDeviceMemo, deleteDeviceMemo } = useClientDeviceMemos(clientId, deviceId)
  const memos = useSWR(`clientDevices/${deviceId}/deviceMemos`, () => listDeviceMemos())

  const author = useMemo(() => members.find((v) => v.id === user?.uid), [members, user])
  const [mem, setMem] = useState('')
  const [memoDeleted, setMemoDeleted] = useState<ClientDeviceMemo | undefined>()

  const handleSubmit = useCallback(async () => {
    if (!author || !mem) {
      return
    }
    try {
      await createDeviceMemo(author, mem)
      setMem('')
      await memos.mutate()

      toast.success('メモを入力しました')
    } catch (e) {
      capture(e)
      toast.error('メモの作成に失敗しました')
    }
  }, [mem, author, createDeviceMemo, memos.mutate])

  const handleDelete = useCallback(async () => {
    if (!memoDeleted) {
      return
    }
    try {
      await deleteDeviceMemo(memoDeleted.id)
      setMemoDeleted(undefined)
      await memos.mutate()

      toast.success('メモを削除しました')
    } catch (e) {
      capture(e)
      toast.error('メモを削除に失敗しました')
    }
  }, [memoDeleted, deleteDeviceMemo, memos.mutate])

  return (
    <>
      <Stack spacing={2}>
        <Stack>
          {memos.data?.map((memo) => {
            const member = members.find((v) => v.id === memo.authorId)
            return (
              <Box>
                <Paper sx={{ p: 2 }}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center" gap={1} mb={1}>
                      <Avatar src={member?.iconURL ?? `${baseURL}/user.png`} sx={{ width: 18, height: 18 }} />
                      <Typography variant="subtitle1">{member?.name ?? memo.authorName}</Typography>
                    </Box>
                    <IconButton onClick={() => setMemoDeleted(memo)}>
                      <IoTrashSharp size={18} />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography variant="body1" whiteSpace="pre-wrap">
                      {memo.content}
                    </Typography>
                  </Box>
                </Paper>
                <Box display="flex" justifyContent={'end'} mt={0.5}>
                  <Typography variant="subtitle2">{formatTimestamp(memo.createdAt)}</Typography>
                </Box>
              </Box>
            )
          })}
        </Stack>
        <Stack spacing={2}>
          <TextField name="memo" multiline rows={3} placeholder="メモを入力してください" onChange={(e) => setMem(e.target.value)} value={mem} />
          <Box display="flex" justifyContent="end">
            <Button variant="contained" onClick={handleSubmit} disabled={!mem}>
              メモする
            </Button>
          </Box>
        </Stack>
      </Stack>
      <BaseDialog
        title="メモを削除しますか？"
        content={memoDeleted?.content ?? ''}
        actionText="削除する"
        open={!!memoDeleted}
        onAction={handleDelete}
        onClose={() => setMemoDeleted(undefined)}
      />
    </>
  )
}
