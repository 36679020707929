import { FirestoreError } from '@firebase/firestore'
import { FirebaseError } from 'firebase/app'

export abstract class DomainError extends Error {
  constructor(message: string, cause?: unknown) {
    super(message)
    this.cause = cause
  }
}

export class ValidationError extends DomainError {
  static {
    this.prototype.name = 'ValidationError'
  }
  constructor(message: string) {
    super(message)
  }
}

type ResourceType =
  | 'client'
  | 'deviceType'
  | 'device'
  | 'deviceMessage'
  | 'deviceSim'
  | 'clientDevice'
  | 'clientInquiry'
  | 'simPlan'
  | 'sim'
  | 'clientApplication'
  | 'user'
  | 'clientInvitation'
  | 'clientMember'
  | 'notification'
  | 'clientPayment'
  | 'clientDeviceMemo'
  | 'simApplication'
  | 'clientInquiryReply'
  | 'clientSubscription'
  | 'invitationCode'
  | 'clientMemberLine'

export const resourceTypeJP = (input: ResourceType): string => {
  switch (input) {
    case 'client':
      return 'グループ'
    case 'clientApplication':
      return '申請'
    case 'clientDevice':
      return '登録済デバイス'
    case 'clientDeviceMemo':
      return 'デバイスメモ'
    case 'clientInquiry':
      return 'お問合せ'
    case 'clientInquiryReply':
      return '返信'
    case 'clientInvitation':
      return '招待'
    case 'clientMember':
      return 'メンバー'
    case 'clientPayment':
      return 'お支払い情報'
    case 'device':
      return 'デバイス'
    case 'deviceMessage':
      return '通信履歴'
    case 'deviceSim':
      return 'デバイスに紐づくSIM'
    case 'deviceType':
      return 'デバイス種別'
    case 'notification':
      return '通知'
    case 'sim':
      return 'SIM'
    case 'simApplication':
      return '通信申請'
    case 'simPlan':
      return '通信プラン'
    case 'clientSubscription':
      return '通信サブスクリプション'
    case 'user':
      return 'ユーザー'
    case 'invitationCode':
      return '招待コード'
    case 'clientMemberLine':
      return 'LINE連携'
  }
}

export class NotFoundError extends DomainError {
  resourceType: ResourceType
  targetId?: string
  static {
    this.prototype.name = 'NotFoundError'
  }
  constructor(resourceType: ResourceType, targetId?: string) {
    super(`${resourceType} not found${targetId ? `: ${targetId}` : ''}`)
    this.resourceType = resourceType
    this.targetId = targetId
  }
}

export class DuplicateError extends DomainError {
  key: string
  value: string

  static {
    this.prototype.name = 'DuplicateError'
  }
  constructor(
    resourceType: ResourceType,
    target: {
      key: string
      value: string
    }
  ) {
    super(`${resourceType} already exists: ${target.key} = ${target.value}`)
    this.key = target.key
    this.value = target.value
  }
}

export class NotAccessibleClientError extends DomainError {
  clientId: string
  static {
    this.prototype.name = 'NotAccessibleClientError'
  }
  constructor(message: string, clientId: string) {
    super(message)
    this.clientId = clientId
  }
}

export class ForbiddenError extends DomainError {
  static {
    this.prototype.name = 'ForbiddenError'
  }
  constructor(message: string) {
    super(message)
  }
}

export class PageNotFoundError extends DomainError {
  static {
    this.prototype.name = 'PageNotFoundError'
  }
  constructor(message: string) {
    super(message)
  }
}

export class UnexpectedError extends DomainError {
  static {
    this.prototype.name = 'UnexpectedError'
  }

  constructor(message: string, options?: unknown) {
    super(message, options)
  }
}

export const getErrorContent = (error: Error): { title: string; desc: string } => {
  if (error instanceof FirestoreError) {
    switch (error.code) {
      case 'aborted':
      case 'already-exists':
      case 'cancelled':
      case 'failed-precondition':
      case 'invalid-argument':
      case 'out-of-range':
      case 'resource-exhausted':
        return {
          title: '不正なリクエスト',
          desc: 'リクエストに失敗しました',
        }
      case 'data-loss':
      case 'deadline-exceeded':
      case 'internal':
      case 'unavailable':
      case 'unimplemented':
      case 'unknown':
        return {
          title: 'システムエラー',
          desc: '申し訳ありませんが時間をおいてから再度お試しください',
        }
      case 'not-found':
        return {
          title: '不正な対象',
          desc: '対象が見つかりません',
        }
      case 'permission-denied':
      case 'unauthenticated':
        return {
          title: '権限不足',
          desc: 'アクセス権限がありません',
        }
    }
  }

  if (error instanceof FirebaseError) {
    switch (error.code) {
      case 'permission-denied':
        return {
          title: '権限不足',
          desc: 'アクセス権限がありません',
        }
    }
  }

  if (error instanceof ValidationError || error instanceof DuplicateError) {
    return {
      title: '不正なリクエスト',
      desc: 'リクエストに失敗しました',
    }
  }
  if (error instanceof ForbiddenError) {
    return {
      title: '権限不足',
      desc: 'アクセス権限がありません',
    }
  }
  if (error instanceof PageNotFoundError) {
    return {
      title: '存在しないページ',
      desc: 'ページが存在しません',
    }
  }
  if (error instanceof NotAccessibleClientError) {
    return {
      title: '権限不足',
      desc: 'アクセス権限がありません',
    }
  }
  if (error instanceof NotFoundError) {
    const title = '存在しない対象'
    const desc = error.resourceType
      ? `対象の${resourceTypeJP(error.resourceType)}${error.targetId && ` (${error.targetId}) `}が存在しません`
      : '対象が見つかりません'
    return {
      title,
      desc,
    }
  }

  return {
    title: '予期せぬエラー',
    desc: '申し訳ありませんが時間をおいてから再度お試しください',
  }
}
