import { ClientInvitation, clientInvitationConverter, createInvitationInput } from '@maru44/huntre-utils/src/models/clientInvitation'
import {
  clientMemberConverter,
  ClientMemberNotificationMethod,
  ClientMemberRole,
  createClientMemberFromAuthUser,
} from '@maru44/huntre-utils/src/models/clientMember'
import { User } from '@maru44/huntre-utils/src/models/user'
import { User as FirebaseUser } from 'firebase/auth'
import { addDoc, and, collection, deleteDoc, doc, getDoc, getDocs, query, runTransaction, serverTimestamp, updateDoc, where } from 'firebase/firestore'
import { useCallback } from 'react'
import { DuplicateError, NotFoundError, ValidationError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'

export const useClientMember = (clientId: string) => {
  const getClientMember = useCallback(
    async (id: string) => {
      const ref = doc(firestore, `clients/${clientId}/clientMembers`, id).withConverter(clientMemberConverter)
      const snap = await getDoc(ref)
      if (!snap.exists) {
        throw new NotFoundError('clientMember', id)
      }
      const data = snap.data()
      if (!data) {
        throw new NotFoundError('clientMember', id)
      }
      return data
    },
    [clientId]
  )

  const listClientMembers = useCallback(async () => {
    const col = collection(firestore, `clients/${clientId}/clientMembers`).withConverter(clientMemberConverter)
    const snap = await getDocs(col)
    return snap.docs.map((v) => v.data())
  }, [clientId])

  const deleteClientMember = useCallback(
    async (id: string) => {
      const ref = doc(firestore, `clients/${clientId}/clientMembers/${id}`).withConverter(clientMemberConverter)
      const snap = await getDoc(ref)
      if (!snap.exists) {
        throw new NotFoundError('clientMember', id)
      }
      const data = snap.data()
      if (!data) {
        throw new NotFoundError('clientMember', id)
      }
      await deleteDoc(ref)
    },
    [clientId]
  )

  const updateClientMemberByAdmin = useCallback(
    async (
      id: string,
      input: {
        role: ClientMemberRole
        notificationMethods: ClientMemberNotificationMethod[]
      }
    ) => {
      const ref = doc(firestore, `clients/${clientId}/clientMembers/${id}`).withConverter(clientMemberConverter)
      const snap = await getDoc(ref)
      if (!snap.exists) {
        throw new NotFoundError('clientMember', id)
      }
      const data = snap.data()
      if (!data) {
        throw new NotFoundError('clientMember', id)
      }
      await updateDoc(ref, {
        ...input,
        updatedAt: serverTimestamp(),
      })
    },
    [clientId]
  )

  const updateClientMemberBySelf = useCallback(
    async (
      id: string,
      input: {
        name: string
        iconURL: string
        phone: string
        notificationMethods: ClientMemberNotificationMethod[]
      }
    ) => {
      const ref = doc(firestore, `clients/${clientId}/clientMembers/${id}`).withConverter(clientMemberConverter)
      const snap = await getDoc(ref)
      if (!snap.exists) {
        throw new NotFoundError('clientMember', id)
      }
      const data = snap.data()
      if (!data) {
        throw new NotFoundError('clientMember', id)
      }
      await updateDoc(ref, {
        ...input,
        updatedAt: serverTimestamp(),
      })
    },
    [clientId]
  )

  const createInvitation = useCallback(
    async (email: string, role: ClientMemberRole, group: { id: string; name: string }, from: User) => {
      const col = collection(firestore, `clients/${clientId}/clientMembers`)
      const q = query(col.withConverter(clientMemberConverter), where('email', '==', email))
      const refs = await getDocs(q)
      if (refs.size > 0 || refs.docs.length > 0) {
        throw new DuplicateError('clientMember', { key: 'email', value: email })
      }

      const now = new Date()
      {
        const input = createInvitationInput(email, group, from, role)
        const col = collection(firestore, `clients/${clientId}/clientInvitations`)
        const q = query(
          col.withConverter(clientInvitationConverter),
          and(where('email', '==', email), where('acceptedAt', '==', null), where('expiresAt', '>=', now), where('deniedAt', '==', null))
        )
        const refs = await getDocs(q)
        if (refs.size > 0 || refs.docs.length > 0) {
          throw new DuplicateError('clientInvitation', { key: 'email', value: email })
        }

        await addDoc(col, input)
      }
    },
    [clientId]
  )

  const listClientInvitations = useCallback(async () => {
    const col = collection(firestore, `clients/${clientId}/clientInvitations`)
    const now = new Date()
    const q = query(
      col.withConverter(clientInvitationConverter),
      and(where('acceptedAt', '==', null), where('deniedAt', '==', null), where('expiresAt', '>=', now))
    )

    const snap = await getDocs(q)

    return snap.docs.map((v) => v.data())
  }, [clientId])

  const deleteInvitation = useCallback(
    async (id: string) => {
      const ref = doc(firestore, `clients/${clientId}/clientInvitations/${id}`)
      const snap = await getDoc(ref)

      if (!snap.exists()) {
        throw new NotFoundError('clientInvitation', id)
      }

      await deleteDoc(ref)
    },
    [clientId]
  )

  return {
    getClientMember,
    listClientMembers,
    deleteClientMember,
    updateClientMemberByAdmin,
    updateClientMemberBySelf,
    createInvitation,
    listClientInvitations,
    deleteInvitation,
  }
}

export const useUpdateClientInvitation = () => {
  // NOTE userをFirebaseUserじゃなくしたい
  const acceptInvitation = useCallback(async (invitation: ClientInvitation, user: FirebaseUser) => {
    const ref = doc(firestore, `clients/${invitation.group.id}/clientInvitations/${invitation.id}`)
    const snap = await getDoc(ref.withConverter(clientInvitationConverter))

    if (!snap.exists()) {
      throw new NotFoundError('clientInvitation', invitation.id)
    }
    const data = snap.data()
    if (!!data.acceptedAt || !!data.deniedAt) {
      throw new ValidationError('invalid invitation')
    }

    await runTransaction(firestore, async (tx) => {
      const now = serverTimestamp()
      tx.update(ref, {
        updatedAt: now,
        acceptedAt: now,
      })

      // メンバードキュメントの作成
      // ここもfirestoreFunctionsに入れちゃって良いかも
      const memberRef = doc(firestore, `clients/${invitation.group.id}/clientMembers/${user.uid}`)
      const memberSnap = await getDoc(memberRef)
      if (memberSnap.exists()) {
        throw new DuplicateError('clientMember', { key: 'userId', value: user.uid })
      }
      tx.set(memberRef, createClientMemberFromAuthUser(user, invitation.role))
    })
  }, [])

  const denyInvitation = useCallback(async (invitation: ClientInvitation) => {
    const ref = doc(firestore, `clients/${invitation.group.id}/clientInvitations/${invitation.id}`)
    const snap = await getDoc(ref.withConverter(clientInvitationConverter))

    if (!snap.exists()) {
      throw new NotFoundError('clientInvitation', invitation.id)
    }
    const data = snap.data()
    if (!!data.acceptedAt || !!data.deniedAt) {
      throw new ValidationError('invalid invitation')
    }

    const now = serverTimestamp()
    await updateDoc(ref, {
      updatedAt: now,
      deniedAt: now,
    })
  }, [])

  return { acceptInvitation, denyInvitation }
}
