import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, Toolbar } from '@mui/material'
import * as Sentry from '@sentry/react'
import { KeyboardEvent, MouseEvent, useCallback, useContext, useState } from 'react'
import { Location, NavLink, Outlet, useLocation } from 'react-router-dom'
import { breadItems } from 'src/components/atoms/BreadCrumbs'
import { Header } from 'src/components/layouts/Header'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { beforeCapture, ErrorFallback } from '../ErrorFallback'
import { Footer } from './Footer'
import { Head } from './Head'

export const BaseLayout = () => {
  const [openDrawer, setOpenDrawer] = useState(false)

  const toggleDrawer = useCallback(
    (open: boolean) => (e: KeyboardEvent | MouseEvent) => {
      if (e && e.type === 'keydown' && ((e as KeyboardEvent).key === 'Tab' || (e as KeyboardEvent).key === 'Shift')) {
        return
      }

      setOpenDrawer(open)
    },
    [setOpenDrawer]
  )

  const { client } = useContext(ClientContext)
  const location = useLocation()

  return (
    <>
      <Head />
      <Header onOpenDrawer={() => setOpenDrawer(!openDrawer)} />
      <main>
        <Box display="flex" mt={9}>
          {client && (
            <>
              <Drawer
                variant="permanent"
                sx={{ display: { xs: 'none', md: 'block', flexShrink: 0, [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' } }, width: 240 }}
              >
                <MenuContent clientId={client?.id ?? ''} location={location} />
              </Drawer>
              <SwipeableDrawer
                anchor="right"
                open={openDrawer}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                sx={{ display: { xs: 'block', md: 'none', flexShrink: 0, [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' } }, width: 240 }}
              >
                <MenuContent clientId={client?.id ?? ''} location={location} />
              </SwipeableDrawer>
            </>
          )}
          <Box sx={{ mx: 'auto', width: '100%', maxWidth: 980, px: 2, pt: 2, pb: 12 }} onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <Sentry.ErrorBoundary fallback={ErrorFallback} beforeCapture={beforeCapture}>
              <Outlet />
            </Sentry.ErrorBoundary>
          </Box>
        </Box>
      </main>
      <Divider />
      <Footer />
    </>
  )
}

type MenuContentProps = {
  clientId: string
  location: Location
}

const MenuContent = ({ clientId, location }: MenuContentProps) => {
  return (
    <>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {breadItems(clientId)
            .filter((v) => !!v.link)
            .map((v) => (
              <ListItem key={v.id} disablePadding component={NavLink} to={v.link!}>
                <ListItemButton selected={location.pathname === v.link!}>
                  {v.icon && <ListItemIcon>{v.icon}</ListItemIcon>}
                  <ListItemText>{v.text}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Box>
    </>
  )
}
