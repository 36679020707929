import { SimConverter } from '@maru44/huntre-utils/src/models/sim'
import { doc, getDoc } from 'firebase/firestore'
import { useCallback } from 'react'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'

export const useSim = () => {
  const getSim = useCallback(async (id: string) => {
    const ref = doc(firestore, `sims/${id}`).withConverter(SimConverter)
    const snap = await getDoc(ref)
    if (!snap.exists) {
      throw new NotFoundError('sim', id)
    }

    const data = snap.data()

    if (!data) {
      throw new NotFoundError('sim', id)
    }

    return data
  }, [])

  return { getSim }
}
