import { DeprecatedThemeOptions, Theme } from '@mui/material'
import { createTheme as createMuiTheme } from '@mui/material/styles'
import { overrides } from 'src/theme/overrides'
import { palette } from 'src/theme/palette'
import { softShadows } from 'src/theme/shadows'
import { typography } from 'src/theme/typography'

export { iconFontSize } from 'src/theme/typography'

export enum ThemeName {
  Light = 'LIGHT',
}

export function createTheme(name: ThemeName): Theme {
  const themeOptions = themesOptions.find((theme) => theme.name === name)
  if (!themesOptions) {
    throw Error('theme not found')
  }
  return createMuiTheme(themeOptions)
}

export function getThemeSpacing(theme: Theme, value: number): number {
  return Number(theme.spacing(value).slice(0, -2))
}

const themesOptions: DeprecatedThemeOptions[] = [
  {
    name: ThemeName.Light,
    light: true,
    direction: 'ltr',
    typography,
    overrides,
    palette,
    shadows: softShadows,
  },
]
