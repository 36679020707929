import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { LoadingLayout } from 'src/components/layouts/LoadingLayout'
import { ClientContext } from 'src/components/providers/ClientProvider'

export const BasicRouterPage = () => {
  const { client, isLoading } = useContext(ClientContext)

  if (isLoading) {
    return <LoadingLayout />
  }
  if (!client) {
    return <Navigate to="/lp" />
  }
  return <Navigate to={`/clients/${client.id}/devices`} />
}
