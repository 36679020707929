import { doc, getDoc } from '@firebase/firestore'
import { ClientSubscription } from '@maru44/huntre-utils/src/models/clientSubscription'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { useCallback } from 'react'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'

export const useClientSubscriptions = (clientId: string) => {
  const getClientSubscription = useCallback(
    async (id: string) => {
      const ref = doc(firestore, `clients/${clientId}/clientSubscriptions/${id}`)
      const snap = await getDoc(ref)

      if (!snap.exists() || !snap.data()) {
        throw new NotFoundError('clientSubscription', id)
      }

      return getData<ClientSubscription>(snap)
    },
    [clientId]
  )

  return { getClientSubscription }
}
