import { FirestoreError } from '@firebase/firestore'
import * as Sentry from '@sentry/react'
import { FirebaseError } from 'firebase/app'
import { ComponentProps } from 'react'
import { getErrorContent } from 'src/utils/error'
import { getSeverityLevel } from 'src/utils/sentry'
import { ErrorLayout } from './layouts/ErrorLayout'

// NOTE: ここではhooksは使えない
export const ErrorFallback = (props: ComponentProps<Sentry.FallbackRender>) => {
  const handleReset = () => {
    location.href = '/'
    props.resetError()
  }

  const { title, desc } = getErrorContent(props.error)
  return <ErrorLayout title={title} description={desc} onClick={handleReset} />
}

export const beforeCapture: Sentry.ErrorBoundaryProps['beforeCapture'] = (scope, error) => {
  if (!error) {
    return
  }

  const severity = getSeverityLevel(error)
  scope.setLevel(severity)
  if (error instanceof FirebaseError) {
    scope.setExtras({
      code: error.code,
      customData: error.customData,
    })
  }
  if (error instanceof FirestoreError) {
    scope.setExtras({
      code: error.code,
    })
  }
}
