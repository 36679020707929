import { Box } from '@mui/material'
import { FC } from 'react'

type Props = {
  src: string
  width: number | string
  height?: number | string
  objectFit: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  alt?: string
}

export const ImageWindow: FC<Props> = ({ src, width, height, objectFit, alt }) => {
  return (
    <Box width={width} height={height} sx={{ position: 'relative' }}>
      {src !== '' && <img src={src} width="100%" style={{ objectFit, width: '100%', height: '100%', position: 'absolute' }} alt={alt} />}
    </Box>
  )
}
