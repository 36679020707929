import { clientDeviceConverter } from '@maru44/huntre-utils/src/models/clientDevice'
import { deviceConverter } from '@maru44/huntre-utils/src/models/device'
import { deviceMessageConverter } from '@maru44/huntre-utils/src/models/deviceMessage'
import {
  and,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore'
import { useCallback } from 'react'
import { DuplicateError, NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'
import { useSimApplication } from '../simApplication/useSimApplication'

export const useClientDevice = (id: string) => {
  const { getActiveSimApplicationByDeviceId } = useSimApplication()

  const getClientDevice = useCallback(
    async (clientId: string) => {
      const cdRef = doc(firestore, `clients/${clientId}/clientDevices/${id}`).withConverter(clientDeviceConverter)
      const snap = await getDoc(cdRef)
      const cd = snap.data()

      if (!cd) {
        throw new NotFoundError('clientDevice', id)
      }
      return cd
    },
    [id]
  )

  const getClientDeviceWithDevice = useCallback(
    async (clientId: string) => {
      const cdRef = doc(firestore, `clients/${clientId}/clientDevices/${id}`).withConverter(clientDeviceConverter)
      const snap = await getDoc(cdRef)
      const cd = snap.data()

      if (!cd) {
        throw new NotFoundError('clientDevice', id)
      }

      const dRef = doc(firestore, `devices/${cd.deviceId}`).withConverter(deviceConverter)
      const dSnap = await getDoc(dRef)
      const d = dSnap.data()

      if (!d) {
        throw new NotFoundError('device', cd.deviceId)
      }

      const simApplication = await getActiveSimApplicationByDeviceId(cd.deviceId)

      return {
        device: d,
        clientDevice: cd,
        simApplication: simApplication,
      }
    },
    [getActiveSimApplicationByDeviceId]
  )

  // 登録日時以降だけにする
  const listMessages = useCallback(
    async (registeredAt: Timestamp) => {
      const q = query(
        collection(firestore, `devices/${id}/deviceMessages`),
        where('createdAt', '>=', registeredAt),
        orderBy('createdAt', 'desc'),
        limit(20)
      ).withConverter(deviceMessageConverter)

      const snap = await getDocs(q)
      return snap.docs.map((v) => v.data())
    },
    [id]
  )

  // 登録日以降 & 捕獲 & setupだけ
  const listMessagesForClient = useCallback(
    async (registeredAt: Timestamp) => {
      const q = query(
        collection(firestore, `devices/${id}/deviceMessages`),
        and(where('createdAt', '>=', registeredAt), where('wakeupCause', 'in', ['ESP_SLEEP_WAKEUP_UNDEFINED', 'ESP_SLEEP_WAKEUP_EXT0'])),
        orderBy('createdAt', 'desc'),
        limit(20)
      ).withConverter(deviceMessageConverter)

      const snap = await getDocs(q)
      return snap.docs.map((v) => v.data())
    },
    [id]
  )

  const deleteClientDevice = useCallback(
    async (clientId: string) => {
      const ref = doc(firestore, `clients/${clientId}/clientDevices/${id}`)

      await deleteDoc(ref)
    },
    [id]
  )

  const updateIsTurnedOff = useCallback(
    async (clientId: string, isTurnedOff: boolean) => {
      const ref = doc(firestore, `clients/${clientId}/clientDevices/${id}`).withConverter(clientDeviceConverter)
      await updateDoc(ref, { isTurnedOff: isTurnedOff, updatedAt: serverTimestamp() })
    },
    [id]
  )

  const createClientDeviceWithId = useCallback(
    async (clientId: string, isTurnedOff?: boolean) => {
      const clientDeviceDocRef = doc(firestore, `clients/${clientId}/clientDevices/${id}`)
      const clientDeviceDoc = await getDoc(clientDeviceDocRef)
      if (clientDeviceDoc.exists()) {
        throw new DuplicateError('clientDevice', { key: 'id', value: id })
      }

      const now = serverTimestamp()
      await setDoc(clientDeviceDocRef, {
        deviceId: id,
        isTurnedOff: isTurnedOff === undefined ? false : isTurnedOff,
        createdAt: now,
        updatedAt: now,
      })
    },
    [id]
  )

  return { getClientDevice, getClientDeviceWithDevice, listMessages, listMessagesForClient, deleteClientDevice, updateIsTurnedOff, createClientDeviceWithId }
}
