import { Box, Button, Typography } from '@mui/material'

type Props = {
  title: string
  description: string
  onClick?: () => void
}

export const ErrorLayout = ({ title, description, onClick }: Props) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="360px" gap={2}>
      <Typography variant="h4">{title}</Typography>
      <Typography textAlign="center">{description}</Typography>
      {onClick && (
        <Button variant="outlined" onClick={onClick}>
          トップページへ
        </Button>
      )}
    </Box>
  )
}
