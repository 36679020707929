import { ClientDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useCallback } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useClientDevice } from 'src/hooks/clientDevice/useClientDevice'
import { capture } from 'src/utils/sentry'

type DeleteClientDeviceDialogProps = {
  clientId: string
  clientDevice: ClientDevice
  open: boolean
  handleClose: () => void
}

export const DeleteClientDeviceDialog = ({ clientId, clientDevice, open, handleClose }: DeleteClientDeviceDialogProps) => {
  const { deleteClientDevice } = useClientDevice(clientDevice.id)
  const navigate = useNavigate()

  const handleDelete = useCallback(async () => {
    try {
      await deleteClientDevice(clientId)
    } catch (e) {
      capture(e)
      toast.error('デバイスを削除できませんでした')
      return
    }
    toast.success('デバイスを削除しました')
    handleClose()

    navigate('/')
  }, [clientId, handleClose, deleteClientDevice, navigate])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>{clientDevice.deviceId}の登録を削除</DialogTitle>
      <DialogContent>
        <Typography>
          {clientDevice.deviceId}の登録を削除します。
          <br />
          デバイスを再度使用する際には再度登録を行なってください。
          <br />
          <br />
          デバイスの登録を削除しても、simの登録は解除されません。simは最初に申請した期限まで有効となります。
        </Typography>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Button onClick={handleDelete} variant="contained" color="error">
            削除
          </Button>
          <Button onClick={handleClose} variant="contained" color="inherit">
            キャンセル
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
