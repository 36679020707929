import { useCallback } from 'react'
import { stripeCli } from 'src/utils/stripe'
import Stripe from 'stripe'

export const useListPrices = () => {
  const listPricesByProduct = useCallback(async (productId: string) => {
    const res = await stripeCli.prices.search({ query: `product:'${productId}' AND active:'true'` })

    return res.data
  }, [])

  const listAllPrices = useCallback(async () => {
    let result: Stripe.Price[] = []
    while (true) {
      const res = await stripeCli.prices.list()
      result.push(...res.data)

      if (!res.has_more) {
        break
      }
    }
    return result
  }, [])

  return { listPricesByProduct, listAllPrices }
}
