import { simPlanConverter } from '@maru44/huntre-utils/src/models/simPlan'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { useCallback } from 'react'
import { firestore } from 'src/utils/firebase'

export const useSimPlans = () => {
  const listSimPlans = useCallback(async () => {
    const q = query(collection(firestore, `simPlans`).withConverter(simPlanConverter), where('isActive', '==', true))
    const snap = await getDocs(q)

    return snap.docs.map((s) => s.data())
  }, [])

  return { listSimPlans }
}
