import { GoogleAuthProvider, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink, signInWithPopup, signOut } from 'firebase/auth'
import { useCallback } from 'react'
import { baseURL } from 'src/config'
import { firebaseAuth } from 'src/utils/firebase'

export const useAuth = () => {
  const googleLogin = useCallback(async () => {
    const provider = new GoogleAuthProvider()
    return await signInWithPopup(firebaseAuth, provider)
  }, [])

  const logout = useCallback(() => {
    return signOut(firebaseAuth)
  }, [])

  const sendAuthEmail = useCallback(async (email: string) => {
    await sendSignInLinkToEmail(firebaseAuth, email, {
      url: `${baseURL}/auth/complete`,
      handleCodeInApp: true,
    })
    window.localStorage.setItem('emailForAuth', email)
  }, [])

  const verifyEmailLink = useCallback(async () => {
    const continueUrl = window.location.href
    if (!isSignInWithEmailLink(firebaseAuth, continueUrl)) {
      return
    }
    const emailInLocalStorage = window.localStorage.getItem('emailForAuth')
    if (!emailInLocalStorage) {
      return
    }
    const res = await signInWithEmailLink(firebaseAuth, emailInLocalStorage, continueUrl)
    return res
  }, [])

  return {
    googleLogin,
    logout,
    sendAuthEmail,
    verifyEmailLink,
  }
}
