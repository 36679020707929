import { InputProps } from '@mui/material'
import { ChangeEventHandler, DetailedHTMLProps, FC, InputHTMLAttributes, Ref } from 'react'

type Props = {
  id: InputHTMLAttributes<InputProps>['id']
  inputRef: Ref<HTMLInputElement>
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const FileInput: FC<Props> = ({ id, inputRef, onChange, ...rest }) => {
  return <input id={id} ref={inputRef} type="file" onChange={onChange} {...rest} />
}
