import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { LoadingLayout } from '../layouts/LoadingLayout'

export function LoginRequiredProvider() {
  const { client, isLoading, error } = useContext(ClientContext)

  if (error) {
    // errorが属さないクライアントなら 404を表示させる
    // リダイレクトはしない！
    throw error
  }

  if (isLoading) {
    return <LoadingLayout />
  }

  if (!client) {
    return <Navigate to="/" />
  }

  return <Outlet />
}
