import { Box, Typography } from '@mui/material'
import { Helmet } from 'react-helmet'
import { useSearchParams } from 'react-router-dom'
import { ErrorLayout } from 'src/components/layouts/ErrorLayout'
import { InquiryRegisterForm } from 'src/components/organisms/inquiry/InquiryRegisterForm'
import { baseURL, isStaging, noreplyEmail, serviceName } from 'src/config'
import { useInvitationCode } from 'src/hooks/invitationCode/useInvitationCode'
import useSWR from 'swr'

export const InquiryRegisterWithCode = () => {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')

  const { getInvitationCode } = useInvitationCode(code ?? '')
  const codeRes = useSWR(`invitationCodes/${code}`, code ? () => getInvitationCode() : null)

  if (!code || (!codeRes.isLoading && codeRes.error)) {
    return <ErrorLayout title="招待が存在しません" description="" />
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta charSet="utf-8" />
        <title>{isStaging ? `${serviceName}(stg) | 登録` : `${serviceName} | 登録`}</title>
        <meta property="og:type" content="product" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:image" content={`${baseURL}/icon.png`} />
      </Helmet>
      {codeRes.data && (
        <>
          <Typography variant="h5">{codeRes.data.name}さま 登録フォーム</Typography>
          <Typography mt={6}>
            下記のフォームをご入力ください。
            <br />
            登録が完了すると運営 ({noreplyEmail})よりメールで通知が送信されます。その後、ログインが可能になります。
          </Typography>
          <Box mt={6}>
            {/* <iframe
                src={registerFormUrl}
                width="100%"
                height="1600px"
                // frameborder="0"
                // marginheight="0"
                // marginwidth="0"
                loading="lazy"
                style={{ border: 'none', borderRadius: '4px', backgroundColor: colors.grey[100] }}
              >
                読み込んでいます…
              </iframe> */}
            <InquiryRegisterForm code={code ?? undefined} />
          </Box>
        </>
      )}
    </>
  )
}
