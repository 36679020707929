import { ClientInquiry, ClientInquiryKind, textClientInquiryKind } from '@maru44/huntre-utils/src/models/clientInquiry'
import { Box, Button, Icon, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { IoCheckmarkSharp } from 'react-icons/io5'
import { Link, useSearchParams } from 'react-router-dom'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { useClientInquiries } from 'src/hooks/clientInquiry/useClientInquiries'
import { formatTimestamp } from 'src/utils/firebase'
import useSWR from 'swr'
import { ClientInquiryFormDialog } from './ClientInquiryFormDialog'

export const ClientInquiriesPage = () => {
  const [user] = useContext(AuthContext)
  const { client } = useContext(ClientContext)

  const [searchParams] = useSearchParams()
  const inquiryKind = searchParams.get('inquiry') as ClientInquiryKind | null

  const { listClientInquiries, createInquiry } = useClientInquiries(client?.id ?? '')
  const clientInquiries = useSWR('clientInquiries', client ? () => listClientInquiries() : null)

  const [openCreate, setOpenCreate] = useState(!!inquiryKind)

  return (
    <>
      <Box>
        <Breads breadIds={['inquiries']} />
        <Stack spacing={2}>
          <Typography variant="h5">お問合せ一覧</Typography>
          <ClientInquiriesTable data={clientInquiries.data} />
          <Box display="flex" alignItems="center" justifyContent="end">
            <Button onClick={() => setOpenCreate(true)} variant="contained">
              お問合せ
            </Button>
          </Box>
        </Stack>
      </Box>
      <ClientInquiryFormDialog
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        onCreate={createInquiry}
        mutate={clientInquiries.mutate}
        userId={user?.uid ?? ''}
        defaultInquiryKInd={inquiryKind}
      />
    </>
  )
}

type ClientInquiriesTableProps = {
  data?: ClientInquiry[]
}

const ClientInquiriesTable = ({ data }: ClientInquiriesTableProps) => {
  return (
    <TableContainer component={Paper} elevation={6}>
      <Table sx={{ minWidth: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">お問合せ内容</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">お問合せ日時</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">解決済</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((d) => (
            <ClientInquiryRow inquiry={d} key={d.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type RowProps = {
  inquiry: ClientInquiry
}

const ClientInquiryRow = ({ inquiry }: RowProps) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Link to={`${inquiry.id}`}>
          <Typography>{inquiry.kind === ClientInquiryKind.Others ? inquiry.title : textClientInquiryKind(inquiry.kind)}</Typography>
        </Link>
      </TableCell>
      <TableCell>
        <Typography>{formatTimestamp(inquiry.createdAt)}</Typography>
      </TableCell>
      <TableCell>
        {!!inquiry.resolvedAt && (
          <Icon color="success">
            <IoCheckmarkSharp />
          </Icon>
        )}
      </TableCell>
    </TableRow>
  )
}
