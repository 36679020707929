import { Box, Stack, Typography } from '@mui/material'
import { useContext } from 'react'
import { toast } from 'react-hot-toast'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { useClients } from 'src/hooks/client/useClients'
import { ClientsTable } from 'src/pages/Clients/Table'
import { capture } from 'src/utils/sentry'
import useSWR from 'swr'
import { Invitations } from './Invitations'

export const Clients = () => {
  const [user] = useContext(AuthContext)
  const { client, selectClient } = useContext(ClientContext)
  const { listClients } = useClients(user!.uid)

  const { data, mutate } = useSWR('clients', () => listClients(), {
    onError: (e) => {
      capture(e)
      toast.error('グループの取得に失敗しました')
    },
  })

  return (
    <>
      <Breads breadIds={['clients']} />
      <Stack spacing={2}>
        <Typography variant="h5">グループ一覧</Typography>
        <Typography>所属するグループ一覧です</Typography>
        <ClientsTable currentClient={client} clients={data ?? []} selectClient={selectClient} />
      </Stack>
      <Box mt={4}>
        <Invitations user={user!} mutateClients={mutate} />
      </Box>
    </>
  )
}
