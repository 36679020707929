import { collection, getDocs, orderBy, query, where } from '@firebase/firestore'
import { ClientSubscriptionInvoice } from '@maru44/huntre-utils/src/models/clientSubscription'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { useCallback } from 'react'
import { firestore } from 'src/utils/firebase'

export const useInvoices = () => {
  const listInvoicesByStripeSubscriptionId = useCallback(async (stripeSubscriptionId: string | null) => {
    const q = query(collection(firestore, `stripeInvoices`), where('stripeSubscriptionId', '==', stripeSubscriptionId), orderBy('stripeCreated', 'desc'))
    const snaps = await getDocs(q)

    return snaps.docs.map((v) => getData<ClientSubscriptionInvoice>(v))
  }, [])

  return { listInvoicesByStripeSubscriptionId }
}
