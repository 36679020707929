import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { useCallback } from 'react'
import { getStorage } from 'src/utils/firebase'

// default bucketのrootにあるディレクトリ
export const DefaultBucketDir = {
  Public: 'public',
  Static: 'static',
} as const
export type DefaultBucketDir = (typeof DefaultBucketDir)[keyof typeof DefaultBucketDir]

export const useStorage = (bucket?: string) => {
  const storage = getStorage(bucket)
  const uploadFile = useCallback(
    async (dir: DefaultBucketDir, file: File) => {
      const storageRef = ref(storage, `${dir}/${file.name}`)
      return await uploadBytes(storageRef, file)
    },
    [storage]
  )

  const getFileURL = useCallback(
    async (path: string, afterGet?: (src: string) => void | Promise<void>) => {
      const imgRef = ref(storage, path)
      const url = await getDownloadURL(imgRef)

      if (afterGet) {
        await afterGet(url)
      }

      return url
    },
    [storage]
  )

  return { uploadFile, getFileURL }
}
