import { DevicePosition } from '@maru44/huntre-utils/src/models/device'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import { useCallback, useState } from 'react'

type Props = {
  deviceName: string
  position: DevicePosition
}

export const DevicePositionMap = (props: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_FIREBASE_API_KEY ?? '',
  })

  const position: google.maps.LatLngLiteral = {
    lat: props.position.lat,
    lng: props.position.lon,
  }

  const [_, setMap] = useState<google.maps.Map | null>(null)
  const onLoad = useCallback(
    (map: google.maps.Map) => {
      map.setZoom(16)
      setMap(map)
    },
    [setMap, position]
  )
  const onUnmount = useCallback(() => {
    setMap(null)
  }, [setMap])

  if (isLoaded) {
    return (
      <GoogleMap mapContainerStyle={{ width: '100%', height: '600px' }} zoom={16} center={position} onLoad={onLoad} onUnmount={onUnmount}>
        <MarkerF position={position} label={props.deviceName} />
      </GoogleMap>
    )
  }
  return <></>
}
