import { Box, Button, Stack } from '@mui/material'
import { DetailedHTMLProps, FC, InputHTMLAttributes, useCallback, useRef, useState } from 'react'
import { FileInput } from '../FileInput'

type Props = {
  onSetFile: (file: File | null) => void
  defaultSrc?: string
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const FileInputArea: FC<Props> = ({ defaultSrc, onSetFile, ...rest }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File | null>(null)
  const [imgSource, setImgSource] = useState<string>('')

  const selectFile = () => {
    if (!fileInputRef.current) return
    // ローカルフォルダーから画像選択のダイアログが表示される。
    fileInputRef.current.click()
  }

  const generateImageSource = useCallback(
    (file: File) => {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        setImgSource(fileReader.result as string)
      }
      fileReader.readAsDataURL(file)
    },
    [setImgSource]
  )

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget?.files && e.currentTarget.files[0]) {
        const targetFile = e.currentTarget.files[0]
        setFile(targetFile)
        generateImageSource(targetFile)
        onSetFile(targetFile)
      }
    },
    [setFile, generateImageSource, onSetFile]
  )

  const handleClickCancel = useCallback(() => {
    setFile(null)
    setImgSource('')
    onSetFile(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }, [fileInputRef, setFile, setImgSource, onSetFile])

  return (
    <Stack>
      <Box display="flex" flexDirection="column" gap={20} alignItems="center" justifyContent="center">
        <Box
          onClick={selectFile}
          display="flex"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          borderRadius={12}
          border="black 3px dotted"
          width={200}
          height={200}
          sx={{
            cursor: 'pointer',
          }}
        >
          {file ? (
            <img src={imgSource} alt={file.name} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
          ) : defaultSrc ? (
            <img src={defaultSrc} alt={defaultSrc} style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
          ) : (
            '画像をアップロード'
          )}
          <FileInput inputRef={fileInputRef} onChange={handleFileChange} id="image" hidden {...rest} />
        </Box>
      </Box>
      <Box>
        <Button variant="contained" onClick={handleClickCancel}>
          x
        </Button>
      </Box>
    </Stack>
  )
}
