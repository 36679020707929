import { useParams } from 'react-router-dom'
import { LoadingLayout } from 'src/components/layouts/LoadingLayout'
import { useDevice } from 'src/hooks/device/useDevice'
import { Register } from 'src/pages/RegisterClientDevice/Register'
import { ValidationError } from 'src/utils/error'
import useSWR from 'swr'
import { ErrorPage } from '../ErrorPage'

export const RegisterClientDevice = () => {
  const { id } = useParams()
  const { getDevice } = useDevice(id ?? '')

  const device = useSWR(`devices/${id}`, id ? () => getDevice() : null)

  if (!id) {
    return <ErrorPage exception={new ValidationError('id required')} />
  }
  if (device.isLoading) {
    return <LoadingLayout />
  }

  if (device.error) {
    return <ErrorPage exception={device.error} />
  }

  return <Register id={id} />
}
