import { zodResolver } from '@hookform/resolvers/zod'
import { ClientDeviceWithDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { Box, Button, Dialog, DialogContent, DialogTitle, InputLabel, TextField, Typography } from '@mui/material'
import { FormEvent, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { capture } from 'src/utils/sentry'
import { KeyedMutator } from 'swr'
import { z } from 'zod'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: (v: FormState) => Promise<void>
  listMutate: KeyedMutator<ClientDeviceWithDevice[]>
}

export const schema = z.object({
  deviceId: z.string().min(2, '2文字以上入力してください'),
})
export type FormState = z.infer<typeof schema>
const defaultValues: FormState = {
  deviceId: '',
}

export const CreateClientDeviceDialog = ({ open, onClose, onSubmit, listMutate }: Props) => {
  const { control, reset, handleSubmit, formState } = useForm<FormState>({
    resolver: zodResolver(schema),
    defaultValues,
    mode: 'all',
  })

  const handleClose = useCallback(() => {
    reset()
    onClose()
  }, [onClose, reset])

  const submit = useCallback(
    async (e: FormEvent) => {
      try {
        e.preventDefault()
        await handleSubmit(onSubmit)()
        toast.success('デバイスを登録しました')
      } catch (e) {
        capture(e)
        toast.error('デバイスの登録に失敗しました')
        return
      }
      listMutate()
      handleClose()
    },
    [onSubmit, handleSubmit, reset, onClose, listMutate, handleClose]
  )

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>デバイスの登録</DialogTitle>
      <DialogContent>
        <Typography>
          デバイスIDを入力してデバイスの登録ができます。
          <br />
          デバイスに貼ってあるQRコードを読み込むことでも登録ができます
        </Typography>
        <Box component="form" onSubmit={submit} mt={4}>
          <Controller
            name="deviceId"
            control={control}
            render={({ field }) => (
              <>
                <InputLabel>デバイスID</InputLabel>
                <TextField fullWidth type="text" {...field} error={!!formState.errors?.deviceId} helperText={formState.errors?.deviceId?.message} />
              </>
            )}
          />
          <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <Button variant="outlined" onClick={handleClose}>
              キャンセル
            </Button>
            <Button variant="contained" type="submit" disabled={!formState.isValid || formState.isSubmitting}>
              作成
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
