import { Box, Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { corporateSiteUrl } from 'src/config'

export const TermsOfUse = () => {
  return (
    <Stack spacing={2} mt={2}>
      <Typography variant="h5">利用規約</Typography>
      <Typography>
        この利用規約（以下「本規約」といいます。）は、株式会社Xenon（以下「当社」といいます。）が提供するIoTデバイスおよびその管理Webアプリ（以下「本サービス」といいます。）の利用に関する条件を定めるものです。本サービスをご利用いただくには、本規約に同意していただく必要があります。本サービスのご利用をもって、本規約に同意していただいたものとみなします。
      </Typography>
      {terms.map((v, i) => (
        <Box key={i}>
          <Typography variant="h6">
            第{i + 1}条（{v.title}）
          </Typography>
          <ol style={{ listStyle: 'decimal' }}>
            {v.type === 'simple' && v.contents.map((title, ii) => <li key={`${i}-${ii}`}>{title}</li>)}
            {v.type === 'complex' &&
              v.contents.map((content, ii) => (
                <li key={`${i}-${ii}`}>
                  {content.subTitle}
                  {content.contents && content.contents.length > 0 && (
                    <ol style={{ listStyleType: 'lower-roman' }}>
                      {content.contents.map((subContent, iii) => (
                        <li key={`${i}-${ii}-${iii}`}>{subContent}</li>
                      ))}
                    </ol>
                  )}
                </li>
              ))}
          </ol>
        </Box>
      ))}
    </Stack>
  )
}

type TitleAndContents =
  | {
      title: string
      type: 'simple'
      contents: (ReactNode | string)[]
    }
  | {
      title: string
      type: 'complex'
      contents: Content[]
    }

type Content = {
  subTitle: string
  contents?: string[]
}

const terms: TitleAndContents[] = [
  {
    title: '通信料の変動',
    type: 'simple',
    contents: ['IoTデバイスの利用に伴う通信料は、変動する場合があります。'],
  },
  {
    title: '利用登録',
    type: 'complex',
    contents: [
      {
        subTitle:
          '本サービスにおいては、登録希望者が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれに対する承認を登録希望者に通知することによって、利用登録が完了するものとします。',
      },
      {
        subTitle:
          '当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。',
        contents: [
          '利用登録の申請に際して虚偽の事項を届け出た場合',
          '本規約に違反したことがある者からの申請である場合',
          'その他、当社が利用登録を相当でないと判断した場合',
        ],
      },
    ],
  },
  {
    // subTitle: '利用規約・プライバシーポリシーの変更',
    // contents: [
    //   '当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約およびプライバシーポリシーを変更することができるものとします。なお、本規約の変更後、本サービスの利用を開始した場合には、当該ユーザーは変更後の規約およびプライバシーポリシーに同意したものとみなします。',
    // ],

    title: '利用規約の変更',
    type: 'simple',
    contents: ['当社は、必要と判断した場合には、会員さまに通知することなくいつでも本規約を変更することができるものとします。'],
  },
  {
    title: '免責事項',
    type: 'complex',
    contents: [
      {
        subTitle:
          'ユーザーの責によらない事由もしくは部品の経年劣化以外の事由によりご利用中に生じた性能の欠陥により機器が正常に作動しない場合、当社は機器を交換します。',
      },
      {
        subTitle: '下記の項目及び、それに類する事に関して、当社は一切の責任を負わないこととします。',
        contents: [
          'ユーザーがレンタル用品の使用、設置、保管によって生じた事故の被害、又は第三者に与えた損害。',
          '機器が使用不可能になった場合のユーザーの損害。',
          '機器の配送遅延等により目的が果せなかった場合のユーザーの損害。',
          '機器の使用不能によりユーザーに発生した損害。',
          'Webアプリの使用不能によりユーザーに発生した損害。',
        ],
      },
      {
        subTitle:
          '機器およびWebアプリがユーザーの想定する使用目的に合致すること、または、有用であることの保証は、当社は一切いたしません。また、上記の目的適合性または有用性を欠くことについての損害賠償はいたしません。',
      },
    ],
  },
  {
    title: 'ユーザーIDおよびパスワードの管理',
    type: 'simple',
    contents: [
      'ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを管理するものとします。',
      'ユーザーID及びパスワードが第三者に使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。',
    ],
  },
  {
    title: '本サービスの提供の停止等',
    type: 'complex',
    contents: [
      {
        subTitle: '本サービスにかかるコンピュータシステムの保守点検または更新を行う場合',
        contents: [
          '本サービスにかかるコンピュータシステムの保守点検または更新を行う場合',
          '地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合',
          'コンピュータまたは通信回線等が事故により停止した場合',
          'その他、当社が本サービスの提供が困難と判断した場合',
        ],
      },
      {
        subTitle:
          '当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。',
      },
    ],
  },
  {
    title: '知的財産権',
    type: 'simple',
    contents: [
      '本サービスによって提供される商品写真その他のコンテンツ（以下「コンテンツ」といいます）の著作権又はその他の知的所有権は,当社及びコンテンツ提供者などの正当な権利者に帰属し,ユーザーは,これらを無断で複製,転載,改変,その他の二次利用をすることはできません。',
    ],
  },
  {
    title: '個人情報の取扱い',
    type: 'simple',
    contents: [
      <Typography>
        当社は、本サービスの利用によって取得する個人情報については、当社
        <Link to={`${corporateSiteUrl}/privacy`} target="_blank">
          「プライバシーポリシー」
        </Link>
        に従い適切に取り扱うものとします。
      </Typography>,
    ],
  },
  {
    title: 'サービス内容の変更等',
    type: 'simple',
    contents: [
      '当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによって会員さまに生じた損害について一切の責任を負いません。',
    ],
  },
  {
    title: '利用制限および登録抹消',
    type: 'complex',
    contents: [
      {
        subTitle:
          '当社は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。',
        contents: [
          '本規約のいずれかの条項に違反した場合',
          '登録事項に虚偽の事実があることが判明した場合',
          '料金等の支払債務の不履行があった場合',
          '当社からの連絡に対し、一定期間返答がない場合',
          'その他、当社が本サービスの利用を適当でないと判断した場合',
        ],
      },
      {
        subTitle: '当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。',
      },
    ],
  },
  {
    title: '禁止事項',
    type: 'simple',
    contents: [
      '本サービスを不当な目的で利用すること。',
      'IIoTデバイスの不当な分解、改造、またはリバースエンジニアリングを行うことを禁止します。これには、デバイスのハードウェアおよびソフトウェアの変更や解析を含みます。',
      'Webサービスのリバースエンジニアリング、逆コンパイル、または逆アセンブルを行うことを禁止します。サービスの構造や仕組みを解析し、再構築することは許可されません。',
      '当社のサーバーまたはネットワークの機能を破壊、妨害するおそれのある行為',
      '不正アクセスをし、またはこれを試みる行為',
      'IoTデバイスの転売を行うこと。',
      '当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為',
      '不正行為や不法行為、サービスの運営に支障をきたす行為、他ユーザーへの迷惑行為を行うこと。',
      'その他、当社が不適切と判断する行為',
    ],
  },
  // TODO こちらの判断による退会
  {
    title: '準拠法および管轄裁判所',
    type: 'simple',
    contents: ['本規約の解釈および適用は、日本法に準拠します。また、本規約に関する紛争は東京地方裁判所を第一審の専属的合意管轄裁判所とします。'],
  },
  // {
  //     subTitle: '損害賠償',
  //     contents: [

  //     ]
  // }
]
