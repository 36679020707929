import { zodResolver } from '@hookform/resolvers/zod'
import { ClientInquiry, ClientInquiryInput, ClientInquiryKind, textClientInquiryKind } from '@maru44/huntre-utils/src/models/clientInquiry'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { FormEvent, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { capture } from 'src/utils/sentry'
import { KeyedMutator } from 'swr'
import { z } from 'zod'

const schema = z.object({
  kind: z.nativeEnum(ClientInquiryKind),
  title: z.string(),
  content: z.string().min(2, '2文字以上入力してください'),
})
export type FormState = z.infer<typeof schema>

type Props = {
  userId: string
  open: boolean
  defaultInquiryKInd: ClientInquiryKind | null
  onCreate: (input: ClientInquiryInput) => Promise<void>
  onClose: () => void
  mutate: KeyedMutator<ClientInquiry[]>
}

export const ClientInquiryFormDialog = ({ userId, open, defaultInquiryKInd, onCreate, onClose, mutate }: Props) => {
  const methods = useForm<FormState>({
    resolver: zodResolver(schema),
    defaultValues: {
      kind: defaultInquiryKInd ?? ClientInquiryKind.DeviceTroubles,
      title: '',
      content: '',
    },
    mode: 'all',
  })

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      try {
        e.preventDefault()
        await methods.handleSubmit(
          async (v) =>
            await onCreate({
              userId: userId,
              kind: v.kind,
              title: v.title || null,
              content: v.content,
            })
        )()
        toast.success('お問合せしました')
      } catch (e) {
        capture(e)
        toast.error('お問合せに失敗しました')
        return
      }
      mutate()
      onClose()
    },
    [onClose, onCreate, mutate, methods.handleSubmit]
  )

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>お問合せ</DialogTitle>
      <Box component="form" onSubmit={handleSubmit}>
        <DialogContent>
          <Stack spacing={2}>
            <Controller
              control={methods.control}
              name="kind"
              render={({ field }) => (
                <>
                  <InputLabel>種類</InputLabel>
                  <Select
                    fullWidth
                    {...field}
                    onChange={(e) => {
                      methods.setValue('kind', e.target.value as ClientInquiryKind)
                      methods.setValue('title', '')
                    }}
                  >
                    {Object.values(ClientInquiryKind).map((v) => (
                      <MenuItem key={v} value={v}>
                        {textClientInquiryKind(v)}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            />
            {methods.watch().kind === ClientInquiryKind.Others && (
              <Controller
                control={methods.control}
                name="title"
                render={({ field }) => (
                  <>
                    <InputLabel>タイトル</InputLabel>
                    <TextField
                      {...field}
                      error={!!methods.formState.errors.title}
                      helperText={methods.formState.errors.title?.message}
                      type="text"
                      required
                      placeholder="タイトルを入力してください"
                    />
                  </>
                )}
              />
            )}
            <Controller
              control={methods.control}
              name="content"
              render={({ field }) => (
                <>
                  <InputLabel>内容</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    rows={6}
                    multiline
                    {...field}
                    error={!!methods.formState.errors.content}
                    helperText={methods.formState.errors.content?.message}
                    required
                    placeholder="内容を入力してください"
                  />
                </>
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box display="flex" width="100%" p={1} justifyContent="space-between">
            <Button variant="outlined" onClick={() => onClose()}>
              キャンセル
            </Button>
            <Button variant="contained" type="submit">
              問い合わせる
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
