import { zodResolver } from '@hookform/resolvers/zod'
import { ClientApplication } from '@maru44/huntre-utils/src/models/clientApplication'
import { Box, Button, Checkbox, InputLabel, Stack, TextField, Typography } from '@mui/material'
import { FC, FormEvent, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import { useHttpsCallable } from 'src/utils/firebase'
import { capture } from 'src/utils/sentry'
import { z } from 'zod'

const schema = z
  .object({
    email: z.string().email('正しいメールアドレスを入力してください'),
    userName: z.string().min(1, '入力してください'),
    groupName: z.string(),
    address: z.string(),
    phone: z.string(),
    isApproved: z.boolean(),
  })
  .refine(
    ({ groupName, address }) => {
      if (groupName) {
        return !!address
      }
      return true
    },
    {
      message: 'グループを作成する場合はグループの住所を入力してください',
      path: ['address'],
    }
  )
  .refine(({ isApproved }) => isApproved, {
    message: '利用規約への同意が必要です',
    path: ['isApproved'],
  })
type FormState = z.infer<typeof schema>

const defaultValues: FormState = {
  email: '',
  userName: '',
  groupName: '',
  address: '',
  phone: '',
  isApproved: false,
}

type Props = {
  code?: string
}

export const InquiryRegisterForm: FC<Props> = ({ code }) => {
  const {
    control,
    reset,
    handleSubmit: _handleSubmit,
    formState,
    setError,
  } = useForm<FormState>({
    resolver: zodResolver(schema),
    defaultValues,
    mode: 'all',
  })

  const [call, isCalling, callError] = useHttpsCallable<
    {
      code?: string
      application: Omit<ClientApplication, 'id' | 'status' | 'createdAt' | 'updatedAt'>
    },
    {
      error?: {
        code?: string
      }
    }
  >('createClientApplication')

  const navigate = useNavigate()

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      let success = false
      e.preventDefault()
      try {
        if (formState.isValid) {
          await _handleSubmit(async (v) => {
            const res = await call({
              application: {
                userName: v.userName,
                email: v.email,
                groupName: v.groupName ?? null,
                address: v.address ?? null,
                phone: v.phone ?? null,
              },
              code,
            })

            if (res?.data?.error?.code === 'already-exists') {
              toast.error(`${v.email}: ユーザーもしくは利用申請が既に存在しています`)
              setError('email', {
                type: 'value',
                message: `${v.email}: ユーザーもしくは利用申請が既に存在しています`,
              })
              return
            }
            success = true
          })()
        }

        if (success) {
          if (code) {
            toast.success('登録に成功しました')
            navigate('/lp')
          } else {
            toast.success('利用申請を受け付けました')
            reset()
          }
        }
      } catch (e) {
        capture(e)
        toast.error(code ? '登録に失敗しました' : '利用申請に失敗しました')
      }
    },
    [code, formState, _handleSubmit, reset, call, navigate]
  )

  return (
    <Stack component="form" spacing={2} onSubmit={handleSubmit}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <>
            <InputLabel required>email</InputLabel>
            <TextField type="email" required {...field} error={!!formState.errors.email} helperText={formState.errors.email?.message} />
          </>
        )}
      />
      <Controller
        name="userName"
        control={control}
        render={({ field }) => (
          <>
            <InputLabel required>ユーザー名</InputLabel>
            <TextField type="text" required {...field} error={!!formState.errors.userName} helperText={formState.errors.userName?.message} />
          </>
        )}
      />
      <Controller
        name="groupName"
        control={control}
        render={({ field }) => (
          <>
            <InputLabel>グループ (任意: グループを作成する場合)</InputLabel>
            <TextField type="text" {...field} error={!!formState.errors.groupName} helperText={formState.errors.groupName?.message} />
          </>
        )}
      />
      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <>
            <InputLabel>住所 (任意: グループを作成する場合は入力してください)</InputLabel>
            <TextField type="text" {...field} error={!!formState.errors.address} helperText={formState.errors.address?.message} />
          </>
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <>
            <InputLabel>電話番号 (任意)</InputLabel>
            <TextField type="text" {...field} error={!!formState.errors.phone} helperText={formState.errors.phone?.message} />
          </>
        )}
      />
      <Controller
        name="isApproved"
        control={control}
        render={({ field }) => (
          <>
            <InputLabel>利用規約への同意</InputLabel>
            <Box display="flex" alignItems="center" gap={2}>
              <Checkbox {...field} />
              <Typography>
                <Link to="/public/termsOfUse" target="_blank">
                  利用規約
                </Link>
                に同意する
              </Typography>
              {formState.errors.isApproved && formState.errors.isApproved.message && (
                <Typography variant="subtitle2" color="red">
                  {formState.errors.isApproved.message}
                </Typography>
              )}
            </Box>
          </>
        )}
      />
      <Box display="flex" justifyContent="end">
        <Button type="submit" variant="contained" disabled={formState.isSubmitting || !formState.isValid || isCalling}>
          申請する
        </Button>
      </Box>
    </Stack>
  )
}
