import { addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, serverTimestamp } from '@firebase/firestore'
import { clientDeviceMemoConverter } from '@maru44/huntre-utils/src/models/clientDeviceMemo'
import { useCallback } from 'react'
import { firestore } from 'src/utils/firebase'

export const useClientDeviceMemos = (clientId: string, deviceId: string) => {
  const listDeviceMemos = useCallback(async () => {
    const col = query(
      collection(firestore, `clients/${clientId}/clientDevices/${deviceId}/clientDeviceMemos`).withConverter(clientDeviceMemoConverter),
      orderBy('createdAt', 'desc')
    )
    const snap = await getDocs(col)

    return snap.docs.map((v) => v.data())
  }, [clientId, deviceId])

  const createDeviceMemo = useCallback(
    async (author: { id: string; name: string }, content: string) => {
      const col = collection(firestore, `clients/${clientId}/clientDevices/${deviceId}/clientDeviceMemos`)

      const now = serverTimestamp()
      await addDoc(col, {
        content,
        authorId: author.id,
        authorName: author.name,
        createdAt: now,
        updatedAt: now,
      })
    },
    [clientId, deviceId]
  )

  const deleteDeviceMemo = useCallback(
    async (id: string) => {
      const ref = doc(firestore, `clients/${clientId}/clientDevices/${deviceId}/clientDeviceMemos/${id}`)

      await deleteDoc(ref)
    },
    [clientId, deviceId]
  )

  return { listDeviceMemos, createDeviceMemo, deleteDeviceMemo }
}
