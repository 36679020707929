import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material'
import { FC, ReactNode } from 'react'

type Props = DialogProps & {
  title: ReactNode
  content: ReactNode
  actionText: string
  onAction: () => void
  onClose: () => void
}

export const BaseDialog: FC<Props> = (props) => {
  return (
    <Dialog maxWidth={props.maxWidth ?? 'md'} {...props}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.content}</DialogContent>
      <DialogActions>
        <Box display="flex" width="100%" p={1} justifyContent="space-between">
          <Button variant="outlined" onClick={() => props.onClose()}>
            キャンセル
          </Button>
          <Button variant="contained" onClick={() => props.onAction()}>
            {props.actionText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
