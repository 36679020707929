import { zodResolver } from '@hookform/resolvers/zod'
import { ClientInquiryReply, ClientInquiryReplyInput } from '@maru44/huntre-utils/src/models/clientInquiry'
import { Box, Button, Stack, TextField } from '@mui/material'
import { FormEvent, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { capture } from 'src/utils/sentry'
import { KeyedMutator } from 'swr'
import { z } from 'zod'

type Props = {
  userId: string
  onSubmit: (input: ClientInquiryReplyInput) => Promise<void>
  mutate: KeyedMutator<ClientInquiryReply[]>
}

const schema = z.object({
  content: z.string().min(2, '2文字以上入力してください'),
})
type FormState = z.infer<typeof schema>

export const ReplyForm = ({ userId, onSubmit, mutate }: Props) => {
  const methods = useForm<FormState>({
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      content: '',
    },
  })

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      try {
        e.preventDefault()
        await methods.handleSubmit(
          async (v) =>
            await onSubmit({
              userId: userId,
              content: v.content,
            })
        )()
        toast.success('返信しました')
      } catch (e) {
        capture(e)
        toast.error('返信できませんでした')
        return
      }
      mutate()
      methods.reset()
    },
    [userId, onSubmit, mutate, methods.reset, methods.handleSubmit]
  )

  return (
    <Stack spacing={1} component="form" onSubmit={handleSubmit}>
      <Controller
        control={methods.control}
        name="content"
        render={({ field }) => (
          <TextField
            fullWidth
            multiline
            rows={6}
            type="text"
            placeholder="返信内容"
            required
            {...field}
            error={!!methods.formState.errors.content}
            helperText={methods.formState.errors.content?.message}
          />
        )}
      />
      <Box display="flex" justifyContent="end">
        <Button type="submit" variant="contained">
          返信する
        </Button>
      </Box>
    </Stack>
  )
}
