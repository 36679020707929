import { doc, getDoc } from '@firebase/firestore'
import { deviceConverter } from '@maru44/huntre-utils/src/models/device'
import { useCallback } from 'react'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'

export const useDevice = (id: string) => {
  const getDevice = useCallback(async () => {
    const deviceRef = doc(firestore, `devices/${id}`).withConverter(deviceConverter)
    const deviceSnap = await getDoc(deviceRef)

    if (!deviceSnap.exists()) {
      throw new NotFoundError('device', id)
    }
    const data = deviceSnap.data()
    if (!data) {
      throw new NotFoundError('device', id)
    }

    return data
  }, [id])

  return { getDevice }
}
