import { Client } from '@maru44/huntre-utils/src/models/client'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { StatusChip } from 'src/components/atoms/StatusChip'

type Props = {
  currentClient: Client | null
  clients: Client[]
  selectClient: (clientId: string) => Promise<void>
}

export const ClientsTable = ({ currentClient, clients, selectClient }: Props) => {
  return (
    <>
      <TableContainer component={Paper} elevation={6}>
        <Table sx={{ minWidth: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">グループ</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">タイプ</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">状態</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((c, i) => (
              <Row client={c} key={i} onSelect={selectClient} isCurrent={c.id === currentClient?.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

type RowProps = {
  client: Client
  isCurrent: boolean
  onSelect: (clientId: string) => Promise<void>
}

const Row = ({ client, isCurrent, onSelect }: RowProps) => {
  const handleSelect = useCallback(async () => {
    await onSelect(client.id)
  }, [client.id, onSelect])
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {/* TODO restrict by application status and status */}
        <Link to={`/clients/${client.id}`}>
          <Typography>{client.name}</Typography>
        </Link>
      </TableCell>
      <TableCell>
        <Typography>{client.type === 'group' ? 'グループ' : 'ユーザー'}</Typography>
      </TableCell>
      <TableCell>
        <StatusChip status={client.applicationStatus} />
      </TableCell>
      <TableCell>
        {isCurrent ? (
          <Typography>選択中</Typography>
        ) : (
          <Button onClick={handleSelect} disabled={isCurrent} variant="contained">
            切り替える
          </Button>
        )}
      </TableCell>
    </TableRow>
  )
}
