import { getNotificationTitle, Notification } from '@maru44/huntre-utils/src/models/notification'
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { useNotification } from 'src/hooks/notification/useNotification'
import { formatTimestamp } from 'src/utils/firebase'
import useSWR from 'swr'
import { ErrorPage } from '../ErrorPage'

export const NotificationsPage = () => {
  const [user] = useContext(AuthContext)
  const { client } = useContext(ClientContext)

  const { listNotifications } = useNotification(client?.id ?? '', user?.uid ?? '')

  const { data, error } = useSWR('notifications', client && user ? () => listNotifications() : null)

  return (
    <>
      <Breads breadIds={['notifications']} />
      <Stack spacing={2}>
        <Typography variant="h5">通知一覧</Typography>
        {data && <NotificationTable notifications={data} />}
        {error && <ErrorPage exception={error} />}
      </Stack>
    </>
  )
}

type TableProps = {
  notifications: Notification[]
}

export const NotificationTable = ({ notifications }: TableProps) => {
  return (
    <TableContainer component={Paper} elevation={6}>
      <Table sx={{ minWidth: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">通知種別</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">要約</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1">日時</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications.map((n) => (
            <Row notification={n} key={n.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type RowProps = {
  notification: Notification
}

const Row = ({ notification }: RowProps) => {
  const { title, summary } = getNotificationTitle(notification)

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Link to={`${notification.id}`}>
          <Typography color={(theme) => (notification.readAt !== null ? theme.palette.action.active : theme.palette.secondary.main)}>{title}</Typography>
        </Link>
      </TableCell>
      <TableCell>
        <Typography>{summary}</Typography>
      </TableCell>
      <TableCell>{formatTimestamp(notification.createdAt)}</TableCell>
    </TableRow>
  )
}
