import { DeviceOrderShippingStatus } from '@maru44/huntre-utils/src/models/deviceOrder'
import { Chip } from '@mui/material'
import { FC } from 'react'
import { ColorAndLabelForStatus } from 'src/utils/status'

type Props = {
  status: DeviceOrderShippingStatus
}

export const ShippingStatusChip: FC<Props> = ({ status }) => {
  const { color, label } = colorAndLabelByStatus(status)
  return <Chip label={label} color={color} />
}

const colorAndLabelByStatus = (status: DeviceOrderShippingStatus): ColorAndLabelForStatus => {
  switch (status) {
    case DeviceOrderShippingStatus.Preparing:
      return {
        color: 'default',
        label: '発送準備中',
      }
    case DeviceOrderShippingStatus.Shipping:
      return {
        color: 'primary',
        label: '配送中',
      }
    case DeviceOrderShippingStatus.Failed:
      return {
        color: 'error',
        label: '配送に失敗しました',
      }
    case DeviceOrderShippingStatus.Success:
      return {
        color: 'success',
        label: '配送済み',
      }
  }
}
