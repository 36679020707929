import { Helmet } from 'react-helmet'
import { baseURL, isStaging, serviceName } from 'src/config'

export const Head = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{isStaging ? `${serviceName}(stg) - IoT罠とその管理サービス` : `${serviceName} - IoT罠とその管理サービス`}</title>
      <meta property="og:title" content={`${serviceName} - IoT罠とその管理サービス`} />
      {/* TODO icon */}
      {isStaging && <meta name="robots" content="noindex" />}
      <meta property="og:type" content="website" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={`${serviceName} - 安価で使いやすいIoT罠とその管理サービスを提供しています`} />
      <meta property="description" content={`${serviceName} - 安価で使いやすいIoT罠とその管理サービスを提供しています`} />
      {/* TODO fix */}
      <meta property="og:url" content={baseURL} />
      {/* SNS */}
      {/* TODO create icon */}
      <meta property="og:image" content={`${baseURL}/icon.png`} />
      {/* TODO end of create icon */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={`${serviceName} - 安価で使いやすいIoT罠とその管理サービスを提供しています`} />
      <meta name="twitter:description" content={`${serviceName} - IoT罠とその管理サービス`} />
      <meta name="twitter:image" content={`${baseURL}/icon.png`} />
    </Helmet>
  )
}
