import { clientConverter } from '@maru44/huntre-utils/src/models/client'
import { clientInvitationConverter } from '@maru44/huntre-utils/src/models/clientInvitation'
import { and, collection, collectionGroup, getDocs, query, where } from 'firebase/firestore'
import { useCallback } from 'react'
import { firestore } from 'src/utils/firebase'

export const useClients = (userId: string) => {
  const listClients = useCallback(async () => {
    const q = query(collection(firestore, `clients`).withConverter(clientConverter), where('clientMemberIds', 'array-contains', userId))
    const snap = await getDocs(q)

    const clients = snap.docs.map((s) => s.data())
    return clients
  }, [userId])

  const listInvitationsByUser = useCallback(async (email: string) => {
    const now = new Date()
    const q = query(
      collectionGroup(firestore, 'clientInvitations').withConverter(clientInvitationConverter),
      and(where('acceptedAt', '==', null), where('expiresAt', '>=', now), where('email', '==', email), where('deniedAt', '==', null))
    )
    const snap = await getDocs(q)
    return snap.docs.map((v) => v.data())
  }, [])

  return { listClients, listInvitationsByUser }
}
