import { zodResolver } from '@hookform/resolvers/zod'
import { SimPlanOrderInput } from '@maru44/huntre-utils/src/models/deviceOrder'
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { FormEvent, useCallback, useContext, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { SimPlanWithPrice } from 'src/components/providers/GlobalProductProvider'
import { useHttpsCallable } from 'src/utils/firebase'
import { capture } from 'src/utils/sentry'
import { z } from 'zod'

type Props = {
  open: boolean
  deviceId: string
  simId: string
  simPlans: SimPlanWithPrice[]
  onClose: () => void
}

type OrderResponse = {
  stripeInvoiceId: string
  stripeInvoiceUrl: string
}

const schema = z.object({
  simPlanId: z.string(),
  // card, customer_balance
  paymentMethod: z.string(),
})
export type FormState = z.infer<typeof schema>

export const ApplySimPlanForm = ({ open, deviceId, simId, simPlans, onClose }: Props) => {
  const { client } = useContext(ClientContext)
  const [user] = useContext(AuthContext)

  const defaultValues = useMemo((): FormState => {
    return {
      simPlanId: simPlans?.find((v) => v.isDefault)?.id ?? '',
      paymentMethod: 'card',
    }
  }, [simPlans])
  const { control, reset, handleSubmit, formState, watch, setValue } = useForm<FormState>({
    resolver: zodResolver(schema),
    defaultValues,
    mode: 'all',
  })
  const current = watch()

  const amount = useMemo(() => {
    return simPlans.find((v) => v.id === current.simPlanId)?.price?.unit_amount
  }, [simPlans, current])

  // createInvoiceから帰ってきた注文と請求に関する情報
  const [order, setOrder] = useState<OrderResponse>()
  const handleClose = useCallback(() => {
    reset()
    onClose()
    setOrder(undefined)
  }, [reset, onClose, setOrder])

  const [call, isCalling, callError] = useHttpsCallable<{ type: string; order: SimPlanOrderInput }, OrderResponse>('createInvoice')
  const handlePush = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      try {
        handleSubmit(async (v) => {
          const order: SimPlanOrderInput = {
            clientId: client?.id ?? '',
            deviceId,
            simId,
            simPlanId: v.simPlanId,
            startsAt: null,
          }

          const input = {
            type: 'simPlan',
            paymentMethod: current.paymentMethod,
            order,
          }
          const response = await call(input)
          console.log(response) // TODO del
          setOrder(response?.data)
        })()
      } catch (e) {
        capture(e)
        toast.error('Stripeでエラーがあり、決済に進めませんでした。時間を置いてからもう一度お試しください')
      }
    },
    [client, current.paymentMethod, user, handleSubmit, call, setOrder]
  )

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>通信の再開申し込み</DialogTitle>
      <DialogContent>
        <Stack component="form" spacing={2} onSubmit={handlePush}>
          <Controller
            name="simPlanId"
            control={control}
            defaultValue={simPlans?.find((v) => v.isDefault)?.id ?? ''}
            render={({ field }) => (
              <>
                <InputLabel>通信プラン</InputLabel>
                <Select
                  fullWidth
                  {...field}
                  error={!!formState.errors?.simPlanId}
                  onChange={(e) => {
                    setValue('simPlanId', e.target.value)
                    setOrder(undefined)
                  }}
                >
                  {simPlans
                    ?.sort((a, b) => a.order - b.order)
                    .map((v) => (
                      <MenuItem value={v.id} key={v.id}>
                        {v.displayName}
                      </MenuItem>
                    ))}
                </Select>
              </>
            )}
          />
          <Controller
            name="paymentMethod"
            control={control}
            defaultValue="card"
            render={({ field }) => (
              <>
                <InputLabel>お支払い方法</InputLabel>
                <Select
                  fullWidth
                  {...field}
                  error={!!formState.errors?.paymentMethod}
                  onChange={(e) => {
                    setValue('paymentMethod', e.target.value)
                    setOrder(undefined)
                  }}
                >
                  <MenuItem value="card">カード</MenuItem>
                  <MenuItem value="customer_balance">口座振替</MenuItem>
                </Select>
              </>
            )}
          />
          <Box>
            <Typography>合計金額: {amount?.toLocaleString('en-US')}円</Typography>
          </Box>
          <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <Button variant="outlined" onClick={handleClose}>
              キャンセル
            </Button>
            {isCalling ? (
              <Box display="flex" alignItems="center" justifyContent="center" m={2}>
                <CircularProgress />
              </Box>
            ) : order ? (
              <a style={{ display: 'contained' }} href={order.stripeInvoiceUrl} target="_blank" rel="noopener noreferrer">
                Stripeでのお支払いに進む
              </a>
            ) : (
              <Button variant="contained" type="submit" disabled={!formState.isValid || formState.isSubmitting || isCalling}>
                購入に進む
              </Button>
            )}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
