import { alpha, colors, DeprecatedThemeOptions } from '@mui/material'
import { palette } from 'src/theme/palette'
import { typography } from 'src/theme/typography'

export const overrides: DeprecatedThemeOptions['overrides'] = {
  MuiLinearProgress: {
    root: {
      borderRadius: 3,
      overflow: 'hidden',
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 32,
    },
  },
  MuiChip: {
    root: {
      color: palette.text.primary,
      backgroundColor: 'white',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      paddingLeft: 8,
    },
    label: {
      padding: 0,
    },
    deleteIcon: {
      width: 14,
      height: 14,
      marginLeft: 4,
      color: 'rgba(66, 82, 110, 0.86)',
      '&:hover': {
        color: 'rgba(66, 82, 110, 0.86)',
      },
    },
  },
  MuiButton: {
    root: {
      minWidth: 'auto',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    outlined: {
      color: palette.text.primary,
      borderColor: colors.grey[400],
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'rgba(248, 248, 248, 1)',
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: palette.text.primary,
    },
  },
  MuiInputBase: {
    root: {
      backgroundColor: 'white',
      '&.Mui-disabled': {
        backgroundColor: palette.action.disabledBackground,
      },
    },
    input: {
      '&::placeholder': {
        opacity: 1,
        color: colors.grey[400],
      },
    },
  },
  MuiTableCell: {
    root: {
      padding: 8,
      borderBottom: 'none',
    },
  },
  MuiOutlinedInput: {
    root: {
      height: 36,
    },
    input: {
      padding: '10.5px 8px',
    },
    notchedOutline: {
      userSelect: 'none',
    },
  },
  MuiCardHeader: {
    root: {
      backgroundColor: alpha(palette.primary.main, 0.08),
    },
    title: {
      ...typography.subtitle1,
    },
    action: {
      marginTop: -8,
      marginBottom: -8,
      marginRight: 0,
    },
  },
  MuiInputLabel: {
    root: {
      userSelect: 'none',
      fontSize: 13,
      marginBottom: 8,
    },
    asterisk: {
      color: palette.error.main,
    },
  },
  MuiRadio: {
    root: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  MuiMenu: {
    root: {
      maxHeight: 300,
    },
  },
  MuiTableHead: {
    root: {
      backgroundColor: palette.background.dark,
    },
  },
  MuiTableRow: {
    root: {
      borderBottom: `1px solid ${palette.divider}`,
      '&$selected, &$selected:hover': {
        backgroundColor: palette.action.selected,
      },
      '&.MuiTableRow-hover:hover': {
        backgroundColor: 'rgba(248, 248, 248, 1)',
      },
    },
  },
  MuiTab: {
    root: {
      textTransform: 'inherit',
    },
  },
}
