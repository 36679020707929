import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { LoadingLayout } from '../layouts/LoadingLayout'
import { AuthContext } from './AuthProvider'
import { ClientContext } from './ClientProvider'

export const AnonymousOnlyProvider = () => {
  const [user, loading, error] = useContext(AuthContext)
  const { client, isLoading: loadingClient } = useContext(ClientContext)
  if (error) {
    return <Navigate to="/" />
  }

  if (loading || loadingClient) {
    return <LoadingLayout />
  }

  if (!!user) {
    return <Navigate to={`/clients/${client?.id ?? user.uid}/devices`} />
  }

  return <Outlet />
}
