import { Box, Divider } from '@mui/material'
import { FallbackRender } from '@sentry/react'
import { ComponentProps } from 'react'
import { ErrorFallback } from 'src/components/ErrorFallback'
import { Footer } from 'src/components/layouts/Footer'
import { Head } from 'src/components/layouts/Head'
import { Header } from 'src/components/layouts/Header'

export const RootErrorFallback = (props: ComponentProps<FallbackRender>) => {
  return (
    <>
      <Head />
      <Header onOpenDrawer={() => {}} />
      <main>
        <Box display="flex" mt={9}>
          <Box sx={{ mx: 'auto', width: '100%', maxWidth: 980, px: 2, pt: 2, pb: 12 }}>
            <ErrorFallback {...props} />
          </Box>
        </Box>
      </main>
      <Divider />
      <Footer />
    </>
  )
}
