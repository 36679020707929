import { ClientApplicationStatus } from '@maru44/huntre-utils/src/models/client'
import { CommunicationStatus } from '@maru44/huntre-utils/src/models/communicationStatus'
import { IsTrappingStatus, OperationStatus } from '@maru44/huntre-utils/src/models/device'
import { Chip } from '@mui/material'
import { ValidationError } from 'src/utils/error'

type ChipStatus = IsTrappingStatus | OperationStatus | ClientApplicationStatus | CommunicationStatus

type ChipColor = 'primary' | 'secondary' | 'info' | 'success' | 'error' | 'warning' | 'default'

type StatusChipProps = {
  status: ChipStatus
}

export const StatusChip = ({ status }: StatusChipProps) => {
  return <Chip label={chipLabelByStatus(status)} color={chipColorByStatus(status)} />
}

const chipColorByStatus = (status: ChipStatus): ChipColor => {
  switch (status) {
    case ClientApplicationStatus.Approved:
    case CommunicationStatus.Ok:
    case OperationStatus.Normal:
      return 'success'
    case IsTrappingStatus.NotTrapping:
      return 'default'
    case OperationStatus.Abnormal:
    case ClientApplicationStatus.Rejected:
    case ClientApplicationStatus.Banned:
    case CommunicationStatus.Terminated:
    case CommunicationStatus.NoSim:
      return 'error'
    case ClientApplicationStatus.Applied:
    case CommunicationStatus.Booked:
      return 'info'
    case CommunicationStatus.Paused:
    case IsTrappingStatus.Trapping:
      return 'warning'
    default:
      throw new ValidationError(`must not reach here: ${status}`)
  }
}

const chipLabelByStatus = (status: ChipStatus): string => {
  switch (status) {
    case IsTrappingStatus.Trapping:
      return '作動'
    case IsTrappingStatus.NotTrapping:
      return '未作動'
    case OperationStatus.Normal:
      return '異常なし'
    case OperationStatus.Abnormal:
      return '異常あり'
    case ClientApplicationStatus.Approved:
      return '有効'
    case ClientApplicationStatus.Rejected:
      return '却下'
    case ClientApplicationStatus.Banned:
      return '禁止'
    case ClientApplicationStatus.Applied:
      return '審査中'
    // sim
    case CommunicationStatus.Ok:
      return '通信可'
    case CommunicationStatus.Paused:
      return '休止中'
    case CommunicationStatus.NoSim:
      return 'simなし'
    case CommunicationStatus.Terminated:
      return '通信凍結'
    case CommunicationStatus.Booked:
      return '休止中(予約済み)'
    default:
      throw new ValidationError(`must not reach here: ${status}`)
  }
}
