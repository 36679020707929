import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { LoadingLayout } from 'src/components/layouts/LoadingLayout'
import { baseURL } from 'src/config'
import { useAuth } from 'src/hooks/useAuth'

export const CompletePage = () => {
  const { verifyEmailLink } = useAuth()

  useEffect(() => {
    ;(async () => {
      const res = await verifyEmailLink()
      if (!res) {
        toast.error('ログインに失敗しました')
        window.location.href = `${baseURL}/auth/login`
        return
      }
      toast.success('ログインに成功しました')
    })()
  }, [])

  return <LoadingLayout />
}
