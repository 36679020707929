import { userConverter } from '@maru44/huntre-utils/src/models/user'
import { doc, getDoc } from 'firebase/firestore'
import { useCallback } from 'react'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'

export const useUser = () => {
  const getUser = useCallback(async (id: string) => {
    const ref = doc(firestore, `users/${id}`).withConverter(userConverter)
    const snap = await getDoc(ref)
    if (!snap.exists) {
      throw new NotFoundError('user', id)
    }
    const data = snap.data()

    if (!data) {
      throw new NotFoundError('user', id)
    }

    return { data, ref }
  }, [])

  return { getUser }
}
