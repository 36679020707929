import { simApplicationConverter } from '@maru44/huntre-utils/src/models/simApplication'
import { collectionGroup, getDocs, query, where } from 'firebase/firestore'
import { useCallback } from 'react'
import { firestore } from 'src/utils/firebase'

export const useSimApplication = () => {
  const getActiveSimApplicationByDeviceId = useCallback(async (deviceId: string) => {
    const q = query(
      collectionGroup(firestore, `simApplications`).withConverter(simApplicationConverter),
      where('deviceId', '==', deviceId),
      where('status', '==', 'active')
    )
    const snap = await getDocs(q)

    if (snap.docs.length === 0) {
      return null
    }
    return snap.docs[0].data()
  }, [])

  return { getActiveSimApplicationByDeviceId }
}
