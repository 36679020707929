import { Box } from '@mui/material'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { corporateSiteUrl } from 'src/config'

// TODO link
export const Footer = () => {
  const { client, isLoading } = useContext(ClientContext)

  return (
    <Box
      //  height="80px"
      display="flex"
      justifyContent="end"
      alignItems="top"
      width="100%"
    >
      <Box
        display="flex"
        alignItems="top"
        sx={{
          height: {
            xs: '300px',
            md: '80px',
          },
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
        gap={1}
        p={1}
      >
        <Box>
          <Link to={corporateSiteUrl} target="_blank">
            運営会社
          </Link>
        </Box>
        <Box>
          <Link to={client ? `/clients/${client.id}/inquiries` : '/inquiry'}>お問合せ</Link>
        </Box>
        {!isLoading && !client && (
          <Box>
            <Link to="/inquiry/register">ワナップ利用申請</Link>
          </Box>
        )}
        <Box>
          <Link to="/public/tokuteishoutorihikihou">特定商取引法に基づく表記</Link>
        </Box>
        <Box>
          <Link to="/public/termsOfUse">利用規約</Link>
        </Box>
      </Box>
    </Box>
  )
}
