import { NotificationConverter } from '@maru44/huntre-utils/src/models/notification'
import { collection, doc, getDoc, getDocs, orderBy, query, serverTimestamp, updateDoc } from 'firebase/firestore'
import { useCallback } from 'react'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'

export const useNotification = (clientId: string, clientMemberId: string) => {
  const listNotifications = useCallback(async () => {
    const col = query(
      collection(firestore, `clients/${clientId}/clientMembers/${clientMemberId}/notifications`).withConverter(NotificationConverter),
      orderBy('createdAt', 'desc')
    )
    const snap = await getDocs(col)

    return snap.docs.map((v) => v.data())
  }, [clientId, clientMemberId])

  const getNotification = useCallback(
    async (id: string) => {
      const ref = doc(firestore, `clients/${clientId}/clientMembers/${clientMemberId}/notifications/${id}`).withConverter(NotificationConverter)
      const snap = await getDoc(ref)
      if (!snap.exists()) {
        throw new NotFoundError('notification', id)
      }

      const now = serverTimestamp()
      await updateDoc(ref, {
        readAt: now,
        updatedAt: now,
      })

      return snap.data()
    },
    [clientId, clientMemberId]
  )

  return { listNotifications, getNotification }
}
