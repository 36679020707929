import { Box, Breadcrumbs, Typography } from '@mui/material'
import { useContext, useMemo } from 'react'
import { IoCart, IoChatbubbles, IoHappy, IoHardwareChip, IoHomeSharp, IoNotifications, IoSettingsSharp } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { ClientContext } from '../providers/ClientProvider'
// import { IconType } from 'react-icons'

export type BreadId = 'home' | 'device' | 'clients' | 'client' | 'notifications' | 'notification' | 'inquiries' | 'inquiry' | 'deviceOrders' | 'profile'

type BreadItem = {
  id: BreadId
  text: string
  link?: string
  icon?: JSX.Element
}

type Props = {
  breadIds: BreadId[]
}

export const breadItems = (clientId: string): BreadItem[] => [
  {
    id: 'home',
    link: `/clients/${clientId}/devices`,
    text: 'デバイス一覧',
    icon: <IoHomeSharp />,
  },
  {
    id: 'device',
    text: 'デバイス詳細',
    icon: <IoHardwareChip />,
  },
  {
    id: 'clients',
    link: '/clients',
    text: 'グループ一覧',
    icon: <IoHappy />,
  },
  {
    id: 'client',
    text: 'グループ詳細',
    icon: <IoHappy />,
  },
  {
    id: 'notifications',
    link: `/clients/${clientId}/notifications`,
    text: '通知一覧',
    icon: <IoNotifications />,
  },
  {
    id: 'notification',
    text: '通知詳細',
    icon: <IoNotifications />,
  },
  {
    id: 'inquiries',
    link: `/clients/${clientId}/inquiries`,
    text: 'お問合せ一覧',
    icon: <IoChatbubbles />,
  },
  {
    id: 'inquiry',
    text: 'お問合せ詳細',
    icon: <IoChatbubbles />,
  },
  {
    id: 'deviceOrders',
    link: `/clients/${clientId}/orders`,
    text: 'デバイス注文',
    icon: <IoCart />,
  },
  {
    id: 'profile',
    text: 'プロフィール設定',
    icon: <IoSettingsSharp />,
  },
]

export const Breads = ({ breadIds }: Props) => {
  const { client } = useContext(ClientContext)
  const breads = useMemo(() => {
    return breadItems(client?.id ?? '').filter((v) => breadIds.includes(v.id))
  }, [breadIds])

  return (
    <Breadcrumbs separator="/" sx={{ mb: 3, mt: 1 }}>
      {breads.map((bread, i) => {
        if (!bread.link && !bread.icon) {
          return (
            <Typography key={bread.id} lineHeight={1}>
              {bread.text}
            </Typography>
          )
        }
        if (!bread.link || i === breadIds.length - 1) {
          return (
            <Box key={bread.id} display="flex" alignItems="center" gap={0.5} lineHeight={1}>
              {bread.icon}
              {bread.text}
            </Box>
          )
        } else if (!bread.icon) {
          return (
            <Link key={bread.id} to={bread.link} className="breadLink">
              <Typography lineHeight={1}>{bread.text}</Typography>
            </Link>
          )
        }

        return (
          <Link key={bread.id} to={bread.link} className="breadLink">
            <Box display="flex" alignItems="center" gap={0.5} lineHeight={1}>
              {bread.icon}
              {bread.text}
            </Box>
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}
