import { collection, getDocs } from '@firebase/firestore'
import { ClientShippingAddress } from '@maru44/huntre-utils/src/models/clientShippingAddress'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { useCallback } from 'react'
import { firestore } from 'src/utils/firebase'

export const useClientShippingAddress = () => {
  const listShippingAddresses = useCallback(async (clientId: string) => {
    const refs = collection(firestore, `clients/${clientId}/clientShippingAddresses`)
    const snaps = await getDocs(refs)

    return snaps.docs.map((v) => getData<ClientShippingAddress>(v))
  }, [])

  return { listShippingAddresses }
}
