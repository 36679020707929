import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { baseURL, env, projectId } from 'src/config'

export const BaseProvider = () => {
  const domain = window.location.host

  useEffect(() => {
    // prodの場合redirect
    if (env === 'prod' && projectId === 'wanapp-prod') {
      if (['wanapp-prod.web.app', 'wanapp-prod.firebaseapp.com'].includes(domain)) {
        //   return <Navigate to={`${baseURL}`} />
        window.location.href = baseURL
      }
    }
  }, [domain])

  return <Outlet />
}
