import { ClientDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { Box, Switch, Typography } from '@mui/material'
import { FC } from 'react'

type Props = {
  clientDevice: ClientDevice
  onUpdate: () => Promise<void>
}

export const ClientDeviceSwitch: FC<Props> = ({ clientDevice, onUpdate }) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography>off</Typography>
      <Switch checked={!clientDevice.isTurnedOff} onChange={onUpdate} color="success" />
      <Typography>on</Typography>
    </Box>
  )
}
