import { addDoc, collection, getDocs, orderBy, query, serverTimestamp } from '@firebase/firestore'
import { clientInquiryConverter, ClientInquiryInput } from '@maru44/huntre-utils/src/models/clientInquiry'
import { useCallback } from 'react'
import { firestore } from 'src/utils/firebase'

export const useClientInquiries = (clientId: string) => {
  const listClientInquiries = useCallback(async () => {
    const q = query(collection(firestore, `clients/${clientId}/clientInquiries`).withConverter(clientInquiryConverter), orderBy('createdAt', 'desc'))
    const refs = await getDocs(q)

    return refs.docs.map((v) => v.data())
  }, [clientId])

  const createInquiry = useCallback(
    async (input: ClientInquiryInput) => {
      const ref = collection(firestore, `clients/${clientId}/clientInquiries`)
      const now = serverTimestamp()
      await addDoc(ref, {
        ...input,
        resolvedAt: null,
        createdAt: now,
        updatedAt: now,
      })
    },
    [clientId]
  )

  return { listClientInquiries, createInquiry }
}
