// TODO simPlanとstripePriceの紐付け
// TODO deviceTypeとstripePriceの紐付け

import { DeviceType } from '@maru44/huntre-utils/src/models/deviceType'
import { SimPlan } from '@maru44/huntre-utils/src/models/simPlan'
import { createContext, useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { stripeConfig } from 'src/config'
import { useDeviceType } from 'src/hooks/deviceType/useDeviceType'
import { useSimPlans } from 'src/hooks/simPlan/useSimPlans'
import { useListPrices } from 'src/hooks/stripe/useListPrices'
import Stripe from 'stripe'

type GlobalProductState = {
  deviceTypes: DeviceType[] | undefined
  devicePrices: Stripe.Price[] | undefined
  simPlans: SimPlanWithPrice[] | undefined
  isLoading: boolean
  isLoadingStripe: boolean
  error: Error | undefined
}

export type SimPlanWithPrice = SimPlan & {
  price: Stripe.Price | undefined
}

export const GlobalProductStateContext = createContext<GlobalProductState>({
  deviceTypes: undefined,
  devicePrices: undefined,
  simPlans: undefined,
  isLoading: false,
  isLoadingStripe: false,
  error: undefined,
})

export function GlobalProductStateProvider() {
  const [loadingPrices, setLoadingPrices] = useState(true)
  const [loadingDeviceTypes, setLoadingDeviceTypes] = useState(true)
  const [loadingSimPlans, setLoadingSimPlans] = useState(true)
  const [devicePrices, setDevicePrices] = useState<Stripe.Price[]>()
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>()
  const [simPlans, setSimPlans] = useState<SimPlanWithPrice[]>()
  const [error, setError] = useState<Error | undefined>()

  const { listPricesByProduct } = useListPrices()
  const { listDeviceTypes } = useDeviceType()
  const { listSimPlans } = useSimPlans()

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const devicePrices = await listPricesByProduct(stripeConfig.productId.device)
        setDevicePrices(devicePrices)
      } catch (e) {
        if (e instanceof Error) {
          setError(e)
        } else {
          setError(new Error('failed to fetch prices'))
        }
      } finally {
        setLoadingPrices(false)
      }
    }

    const fetchDeviceTypes = async () => {
      try {
        const deviceTypes = await listDeviceTypes()
        setDeviceTypes(deviceTypes)
      } catch (e) {
        if (e instanceof Error) {
          setError(e)
        } else {
          setError(new Error('failed to fetch deviceTypes'))
        }
      } finally {
        setLoadingDeviceTypes(false)
      }
    }

    const fetchSimPlans = async () => {
      try {
        const simPlanPirces = await listPricesByProduct(stripeConfig.productId.simPlan)

        const simPlans = await listSimPlans()
        setSimPlans(
          simPlans.map((v) => {
            return {
              ...v,
              price: simPlanPirces.find((p) => v.stripePriceId === p.id),
            }
          })
        )
      } catch (e) {
        if (e instanceof Error) {
          setError(e)
        } else {
          setError(new Error('failed to fetch simPlans'))
        }
      } finally {
        setLoadingSimPlans(false)
      }
    }

    fetchDeviceTypes()
    fetchSimPlans()
    fetchPrices()
  }, [setDevicePrices, setDeviceTypes, listDeviceTypes, setDeviceTypes, setLoadingDeviceTypes, setSimPlans, setLoadingSimPlans])

  const ctx = useMemo(
    (): GlobalProductState => ({
      deviceTypes,
      devicePrices,
      simPlans,
      isLoading: loadingDeviceTypes || loadingSimPlans,
      isLoadingStripe: loadingPrices,
      error,
    }),
    [devicePrices, simPlans, deviceTypes, loadingDeviceTypes, loadingSimPlans, loadingPrices, error]
  )

  return (
    <GlobalProductStateContext.Provider value={ctx}>
      <Outlet />
    </GlobalProductStateContext.Provider>
  )
}

type DeviceTypeWithPrice = {
  deviceType: DeviceType
  price: Stripe.Price
}

export const getDeviceTypeWithPrices = (deviceTypes?: DeviceType[], prices?: Stripe.Price[]) => {
  if (!deviceTypes || !prices) {
    return undefined
  }

  const out = deviceTypes.reduce((acc, curr) => {
    const price = prices.find((v) => v.id === curr.stripePriceId)
    if (price) {
      acc.push({
        deviceType: curr,
        price,
      })
    }
    return acc
  }, [] as DeviceTypeWithPrice[])

  return out
}
