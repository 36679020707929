import { FirestoreError } from '@firebase/firestore'
import { captureException as sentryCaptureException, SeverityLevel, withScope } from '@sentry/react'
import { FirebaseError } from 'firebase/app'
import { DomainError, UnexpectedError } from './error'

export const capture = (e: unknown) => {
  if (e instanceof Error) {
    const severity = getSeverityLevel(e)
    if (e instanceof FirestoreError) {
      withScope((s) => {
        s.setLevel(severity)
        s.setExtras({
          code: e.code,
        })
        sentryCaptureException(e)
      })
      return
    }
    if (e instanceof FirebaseError) {
      withScope((s) => {
        s.setLevel(severity)
        s.setExtras({
          code: e.code,
          customData: e.customData,
        })
        sentryCaptureException(e)
      })
      return
    }

    withScope((s) => {
      s.setLevel(severity)
      sentryCaptureException(e)
    })
  } else {
    sentryCaptureException(e)
  }
}

export const getSeverityLevel = (error: Error): SeverityLevel => {
  if (error instanceof UnexpectedError) {
    return 'error'
  }
  if (error instanceof DomainError) {
    return 'warning'
  }

  if (error instanceof FirestoreError) {
    switch (error.code) {
      case 'aborted':
      case 'already-exists':
      case 'cancelled':
      case 'failed-precondition':
      case 'invalid-argument':
      case 'out-of-range':
      case 'resource-exhausted':
      case 'not-found':
      case 'permission-denied':
      case 'unauthenticated':
        return 'warning'
      case 'data-loss':
      case 'deadline-exceeded':
      case 'internal':
      case 'unavailable':
      case 'unimplemented':
      case 'unknown':
        return 'fatal'
    }
  }
  if (error instanceof FirebaseError) {
    switch (error.code) {
      case 'permission-denied':
        return 'warning'
    }
  }

  return 'error'
}
