import { Box, BoxProps, Grid, Theme, Typography } from '@mui/material'
import { ReactNode } from 'react'

export type SimpleGridRow = {
  head?: ReactNode
  body?: ReactNode
  alignItems?: 'center' | 'top'
  height?: number
}

type SimpleGridProps = {
  rows: SimpleGridRow[]
  noDataText?: string
  headSize?: number
  bodySize?: number
  itemMarginBottom?: number
  height?: number
} & BoxProps

export function SimpleGrid({ rows, noDataText, headSize = 4, bodySize = 8, itemMarginBottom = 3, height, ...rest }: SimpleGridProps): JSX.Element {
  return (
    <Box {...rest}>
      {rows.map((row, i) => (
        <Grid
          container
          key={i}
          mb={i === rows.length - 1 ? 0 : itemMarginBottom}
          alignItems={row.alignItems ?? rest.alignItems ?? 'center'}
          minHeight={getHeight(row.height, height)}
        >
          <Grid item xs={headSize}>
            {row.head ?? <></>}
          </Grid>
          <Grid item xs={bodySize}>
            {row.body ?? <></>}
          </Grid>
        </Grid>
      ))}
      {rows.length === 0 && noDataText && (
        <Typography color="text.disabled" variant="body1">
          {noDataText}
        </Typography>
      )}
    </Box>
  )
}

const getHeight = (rowHeight?: number, height?: number) => {
  return (theme: Theme) => {
    if (rowHeight) {
      return theme.spacing(rowHeight)
    }
    if (height) {
      return theme.spacing(height)
    }
    return undefined
  }
}
