import { Typography } from '@mui/material'
import { HelpPopover } from '../atoms/HelpPopover'

export const PopoverWhatIsTurnedOff = () => {
  return (
    <HelpPopover>
      <Typography>
        offにすると捕獲通知や異常検知が来なくなります。
        {/* <br />
            <br />
            デバイスのスイッチをオンにして起動メッセージを受け取るとこの値は自動でonになります。 */}
      </Typography>
    </HelpPopover>
  )
}
