import { ClientDeviceWithDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { OperationStatus } from '@maru44/huntre-utils/src/models/device'
import { Typography } from '@mui/material'
import { GoogleMap, InfoWindowF, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatTimestamp } from 'src/utils/firebase'

type Props = {
  devices: ClientDeviceWithDevice[]
}

export const DevicePositionsMap = (props: Props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_FIREBASE_API_KEY ?? '',
  })

  const getPosition = useCallback(() => {
    let position: google.maps.LatLngLiteral = { lat: 0, lng: 0 }
    // // 自分の現在地
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((p) => {
    //     position = {
    //       lat: p.coords.latitude,
    //       lng: p.coords.longitude,
    //     }
    //   })
    // }

    if (props.devices.length > 0) {
      position = {
        lat: props.devices[0].device.position!.lat,
        lng: props.devices[0].device.position!.lon,
      }
    }
    return position
  }, [props.devices])

  const [_, setMap] = useState<google.maps.Map | null>(null)
  const onLoad = useCallback(
    (map: google.maps.Map) => {
      map.setZoom(16)

      setMap(map)
    },
    [setMap, getPosition]
  )
  const onUnmount = useCallback(() => {
    setMap(null)
  }, [setMap])

  if (isLoaded && props.devices.length > 0) {
    return (
      <GoogleMap mapContainerStyle={{ width: '100%', height: '600px' }} zoom={16} center={getPosition()} onLoad={onLoad} onUnmount={onUnmount}>
        <MarkerF position={getPosition()} icon="https://maps.google.com/mapfiles/ms/micons/blue-dot.png" zIndex={0} />
        {props.devices.map((v) => (
          <Pin device={v} />
        ))}
      </GoogleMap>
    )
  }
  return <></>
}

type PinProps = {
  device: ClientDeviceWithDevice
}

type PinStatus = 'trapping' | 'dormant' | 'normal' | 'turnedOff'

const Pin = ({ device }: PinProps) => {
  const [openInfo, setOpenInfo] = useState<boolean>(false)
  const position = device.device.position!

  return (
    <MarkerF
      position={{ lat: position.lat, lng: position.lon }}
      label={device.device.id}
      clickable
      onClick={() => {
        setOpenInfo(true)
      }}
      zIndex={100}
      icon={getPinIcon(getPinStatus(device))}
    >
      {openInfo && (
        <InfoWindowF onCloseClick={() => setOpenInfo(false)}>
          <div>
            <Typography variant="h6">{device.device.id}</Typography>
            {device.clientDevice.isTurnedOff && <Typography color={(theme) => theme.palette.action.disabled}>通知off</Typography>}
            {!device.clientDevice.isTurnedOff && device.device.isTrapping && <Typography color={(theme) => theme.palette.primary.dark}>罠作動!!</Typography>}
            {!device.clientDevice.isTurnedOff && device.device.operationStatus === OperationStatus.Abnormal && (
              <Typography color={(theme) => theme.palette.error.main}>異常あり!!</Typography>
            )}
            {device.device.positionUpdatedAt && <Typography>位置情報取得日時: {formatTimestamp(device.device.positionUpdatedAt)}</Typography>}
            <Typography>
              デバイスの詳細は<Link to={`${device.device.id}`}>こちら</Link>
            </Typography>
          </div>
        </InfoWindowF>
      )}
    </MarkerF>
  )
}

const getPinStatus = (device: ClientDeviceWithDevice): PinStatus => {
  if (device.clientDevice.isTurnedOff) {
    return 'turnedOff'
  }
  if (device.device.isTrapping) {
    return 'trapping'
  }
  if (device.device.operationStatus === OperationStatus.Abnormal) {
    return 'dormant'
  }

  return 'normal'
}

// https://www.single-life.tokyo/google-maps%EF%BC%88%E3%82%B0%E3%83%BC%E3%82%B0%E3%83%AB%E3%83%9E%E3%83%83%E3%83%97%EF%BC%89%E3%81%A7%E4%BD%BF%E3%81%88%E3%82%8B%E3%82%A2%E3%82%A4%E3%82%B3%E3%83%B3/
const getPinIcon = (status: PinStatus) => {
  switch (status) {
    case 'normal':
      return 'https://maps.google.com/mapfiles/ms/micons/green-dot.png'
    case 'dormant':
      return 'https://maps.google.com/mapfiles/ms/micons/purple-dot.png'
    case 'turnedOff':
      return 'https://labs.google.com/ridefinder/images/mm_20_gray.png'
    case 'trapping':
      return 'https://maps.google.com/mapfiles/ms/micons/orange-dot.png'
  }
}
