import { useEffect } from 'react'
import { ErrorLayout } from 'src/components/layouts/ErrorLayout'
import { getErrorContent, UnexpectedError } from 'src/utils/error'
import { capture } from 'src/utils/sentry'

type Props = {
  exception: unknown
  skipCapture?: boolean
  onClick?: () => void
}

export const ErrorPage = ({ exception, skipCapture = false, onClick }: Props) => {
  const error = exception instanceof Error ? exception : new UnexpectedError('unexpected exception', exception)
  const { title, desc } = getErrorContent(error)

  // NOTE: ここでやるべきじゃないかも
  useEffect(() => {
    if (!skipCapture) {
      capture(error)
    }
  }, [error, skipCapture])

  return <ErrorLayout title={title} description={desc} onClick={onClick} />
}
