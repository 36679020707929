import { Client } from '@maru44/huntre-utils/src/models/client'
import { ClientInvitation } from '@maru44/huntre-utils/src/models/clientInvitation'
import { Button, Divider, IconButton, Paper, Popover, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { User } from 'firebase/auth'
import { MouseEvent, useCallback, useState } from 'react'
import { toast } from 'react-hot-toast'
import { IoEllipsisVerticalSharp } from 'react-icons/io5'
import { BaseDialog } from 'src/components/atoms/BaseDialog'
import { useUpdateClientInvitation } from 'src/hooks/client/useClientMember'
import { useClients } from 'src/hooks/client/useClients'
import { formatTimestamp } from 'src/utils/firebase'
import { capture } from 'src/utils/sentry'
import useSWR, { KeyedMutator } from 'swr'

type Props = {
  user: User
  mutateClients: KeyedMutator<Client[]>
}

export const Invitations = ({ user, mutateClients }: Props) => {
  const { listInvitationsByUser } = useClients(user.uid)
  const invitations = useSWR(`${user.uid}/clientInvitations`, () => listInvitationsByUser(user.email ?? ''), {
    onError: (e) => {
      capture(e)
      toast.error('招待が取得できませんでした')
    },
  })

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [invitation, setInvitation] = useState<ClientInvitation | undefined>()
  const [openAccept, setOpenAccept] = useState<boolean>(false)
  const [openDeny, setOpenDeny] = useState<boolean>(false)

  const { acceptInvitation, denyInvitation } = useUpdateClientInvitation()

  const handleAccept = useCallback(async () => {
    try {
      if (!invitation) {
        return
      }
      await acceptInvitation(invitation, user)
      toast.success('招待を受理しました')
    } catch (e) {
      capture(e)
      toast.error('招待が受理できませんでした')
      return
    }
    await invitations.mutate()
    await mutateClients()
    setInvitation(undefined)
  }, [invitations.mutate, invitation, acceptInvitation, setInvitation, user, mutateClients])

  const handleDeny = useCallback(async () => {
    try {
      if (!invitation) {
        return
      }
      await denyInvitation(invitation)
      toast.success('招待を辞退しました')
    } catch (e) {
      capture(e)
      toast.error('招待が辞退できませんでした')
      return
    }
    await invitations.mutate()
    setInvitation(undefined)
  }, [invitations.mutate, invitation, denyInvitation, setInvitation])

  if (!invitations.data || invitations.data.length === 0) {
    return null
  }

  return (
    <>
      <Stack spacing={2}>
        <Typography>グループへの招待が届いています</Typography>
        <TableContainer component={Paper} elevation={6}>
          <Table sx={{ minWidth: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">グループ名</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">権限</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">招待者</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">期限</Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invitations.data?.map((v) => (
                <Row
                  data={v}
                  key={v.id}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget)
                    setInvitation(v)
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Popover open={!!anchorEl} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        <Stack>
          <Typography component={Button} onClick={() => setOpenAccept(true)}>
            参加する
          </Typography>
          <Divider />
          <Typography component={Button} onClick={() => setOpenDeny(true)}>
            辞退する
          </Typography>
        </Stack>
      </Popover>
      {/* accept dialog */}
      <BaseDialog
        maxWidth="sm"
        fullWidth
        open={openAccept && !!invitation}
        onClose={() => {
          setOpenAccept(false)
          setAnchorEl(null)
        }}
        title={`${invitation?.group.name}への参加`}
        content={`${invitation?.group.name}に参加しますか？`}
        actionText="参加する"
        onAction={handleAccept}
      />
      <BaseDialog
        maxWidth="sm"
        fullWidth
        open={openDeny && !!invitation}
        onClose={() => {
          setOpenDeny(false)
          setAnchorEl(null)
        }}
        title={`${invitation?.group.name}への参加を辞退します`}
        content="辞退しますか？"
        actionText="辞退する"
        onAction={handleDeny}
      />
    </>
  )
}

type RowProps = {
  data: ClientInvitation
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

const Row = ({ data, onClick }: RowProps) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography>{data.group.name}</Typography>
      </TableCell>
      <TableCell>{data.role === 'admin' ? '管理' : 'メンバー'}</TableCell>
      <TableCell>
        {data.from.name} ({data.from.email})
      </TableCell>
      <TableCell>{formatTimestamp(data.expiresAt!)}</TableCell>
      <TableCell>
        <IconButton onClick={onClick}>
          <IoEllipsisVerticalSharp />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
