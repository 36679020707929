import { createContext, ReactNode } from 'react'
import { AuthStateHook, useAuthState } from 'react-firebase-hooks/auth'
import { firebaseAuth } from 'src/utils/firebase'

export const AuthContext = createContext<AuthStateHook>([null, false, undefined])

type AuthProviderProps = {
  children: ReactNode
}

export function AuthProvider({ children }: AuthProviderProps) {
  return <AuthContext.Provider value={useAuthState(firebaseAuth)}>{children}</AuthContext.Provider>
}
