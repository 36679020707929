import * as Sentry from '@sentry/react'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import App from 'src/App'
import { env, isDevelopment, sentryDSN } from 'src/config'
import 'src/index.css'

Sentry.init({
  enabled: !isDevelopment,
  dsn: sentryDSN,
  environment: env,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
    }),
    new Sentry.Replay(),
  ],
  ignoreErrors: ['PageNotFoundError'],

  // integrations: [
  //   new Sentry.BrowserTracing({
  //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  //   }),
  //   new Sentry.Replay(),
  // ],

  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// unhandledのものをfatalにするためデフォルトでfatal
Sentry.withScope((s) => s.setLevel('fatal'))

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
