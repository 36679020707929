import { getNotificationTitle, Notification, NotificationType } from '@maru44/huntre-utils/src/models/notification'
import { Box, Stack, Typography } from '@mui/material'
import { ReactNode, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Breads } from 'src/components/atoms/BreadCrumbs'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { useNotification } from 'src/hooks/notification/useNotification'
import { formatTimestamp } from 'src/utils/firebase'
import useSWR from 'swr'
import { ErrorPage } from '../ErrorPage'

export const NotificationPage = () => {
  const { id } = useParams()
  const [user] = useContext(AuthContext)
  const { client } = useContext(ClientContext)
  const { getNotification } = useNotification(client?.id ?? '', user?.uid ?? '')

  const notification = useSWR(`notifications/${id}`, id && user && client ? () => getNotification(id) : null)

  return (
    <Box>
      <Breads breadIds={['notifications', 'notification']} />
      <Stack spacing={2}>
        <Typography variant="h5">通知詳細</Typography>
        {notification.data && <NotificationContent clientId={client?.id ?? ''} data={notification.data} key={notification.data.id} />}
        {notification.error && <ErrorPage exception={notification.error} />}
      </Stack>
    </Box>
  )
}

type ContentProps = {
  data: Notification
  clientId: string
}

const NotificationContent = ({ data, clientId }: ContentProps) => {
  const { summary } = getNotificationTitle(data)
  return (
    <>
      <Typography variant="h6">{summary}</Typography>
      {getNotificationContent(data, clientId)}
      <Box display="flex" justifyContent="right">
        {formatTimestamp(data.createdAt)}
      </Box>
    </>
  )
}

const getNotificationContent = (notification: Notification, clientId: string): ReactNode => {
  switch (notification.type) {
    case NotificationType.Dormant:
      return (
        <Box py={2}>
          デバイス<Link to={`/clients/${clientId}/devices/${notification.clientDeviceIds[0]}`}>{notification.clientDeviceIds[0]}</Link>
          に異常の可能性があります。
          <br />
          電池切れや故障、通信障害等が考えられます。
          <br />
          ご確認ください。
        </Box>
      )
    case NotificationType.Setup:
      return (
        <Box py={2}>
          デバイス<Link to={`/clients/${clientId}/devices/${notification.clientDeviceIds[0]}`}>{notification.clientDeviceIds[0]}</Link>の電源onを探知しました。
        </Box>
      )
    case NotificationType.Trapping:
      return (
        <Box py={2}>
          デバイス<Link to={`/clients/${clientId}/devices/${notification.clientDeviceIds[0]}`}>{notification.clientDeviceIds[0]}</Link>が作動しました。
          <br />
          獲物がかかった可能性があります。
        </Box>
      )
    case NotificationType.ReceiveInvitation:
      return (
        <Box py={2}>
          {notification.sentBy?.userName}さんから{notification.sentBy?.clientName}(グループ)に招待されました。
          <br />
          <Link to="/clients">こちら</Link>から招待を確認できます。
        </Box>
      )
    case NotificationType.Raw:
      return <Box py={2}>{notification.rawMessage}</Box>
  }
}
