import { DeviceOrder, DeviceOrderPaymentStatus } from '@maru44/huntre-utils/src/models/deviceOrder'
import { Box, Chip, Icon, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import { MdLocalShipping } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { ShippingStatusChip } from 'src/components/atoms/chip/ShippingStatusChip'
import { SimPlanWithPrice } from 'src/components/providers/GlobalProductProvider'
import { formatTimestampToDate } from 'src/utils/firebase'

type Props = {
  orders: DeviceOrder[] | undefined
  simPlans: SimPlanWithPrice[] | undefined
}

export const DeviceOrdersTable = ({ orders, simPlans }: Props) => {
  return (
    <TableContainer component={Paper} elevation={6}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>デバイスタイプ (個数)</TableCell>
            <TableCell>通信プラン</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>注文日</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders?.map((v) => (
            <TableRow key={v.id}>
              <TableCell>
                {v.stripeInvoiceUrl ? (
                  <Link to={v.stripeInvoiceUrl} target="_blank">
                    {v.deviceTypeId} ({v.count})
                  </Link>
                ) : (
                  `${v.deviceTypeId} (${v.count})`
                )}
              </TableCell>
              <TableCell>{simPlans?.find((s) => s.id === v.simPlanId)?.displayName}</TableCell>
              <TableCell>
                <Box display="flex" alignItems="center" gap={2}>
                  {v.shippingStatus && <ShippingStatusChip status={v.shippingStatus} />}
                  {!v.shippingStatus && v.paymentStatus === DeviceOrderPaymentStatus.Waiting && <Chip color="info" label="入金待ち" />}
                  <Tooltip title={`${v.shippingAddress.postalCode}\n${v.shippingAddress.address}`}>
                    <Icon>
                      <MdLocalShipping />
                    </Icon>
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell>{formatTimestampToDate(v.createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
