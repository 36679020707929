import { ClientDevice, clientDeviceConverter, ClientDeviceInput, ClientDeviceWithDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { Device, deviceConverter } from '@maru44/huntre-utils/src/models/device'
import { collection, doc, documentId, getDoc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore'
import { useCallback } from 'react'
import { DuplicateError, NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'

export const useClientDevices = (clientId: string) => {
  const listClientDevicesWithDevice = useCallback(async () => {
    const udCollectionRef = collection(firestore, `clients/${clientId}/clientDevices`).withConverter(clientDeviceConverter)
    const snap = await getDocs(udCollectionRef)

    let data: ClientDeviceWithDevice[] = []
    let deviceIds: string[] = []
    let deviceById: { [key: string]: Device } = {}

    snap.forEach((ss) => {
      deviceIds.push((ss.data() as ClientDevice).deviceId)
    })

    for (const ids of sliceByNum(deviceIds, 10)) {
      const ref = query(collection(firestore, `devices`), where(documentId(), 'in', ids)).withConverter(deviceConverter)
      const devices = await getDocs(ref)
      devices.forEach((ss) => {
        deviceById[ss.id] = ss.data()
      })
    }

    snap.forEach((ss) => {
      const ud = ss.data()
      data.push({
        clientDevice: ss.data(),
        device: deviceById[ud.deviceId],
      })
    })

    return data
  }, [clientId])

  const createClientDevice = useCallback(
    async (input: ClientDeviceInput) => {
      const deviceDocRef = doc(firestore, `devices/${input.deviceId}`)
      const deviceDoc = await getDoc(deviceDocRef)

      if (!deviceDoc.exists()) {
        throw new NotFoundError('device', input.deviceId)
      }

      const clientDeviceDocRef = doc(firestore, `clients/${clientId}/clientDevices/${input.deviceId}`)
      const clientDeviceDoc = await getDoc(clientDeviceDocRef)
      if (clientDeviceDoc.exists()) {
        throw new DuplicateError('clientDevice', {
          key: 'deviceId',
          value: input.deviceId,
        })
      }
      const now = serverTimestamp()
      await setDoc(clientDeviceDocRef, {
        deviceId: input.deviceId,
        isTurnedOff: input.isTurnedOff === undefined ? false : input.isTurnedOff,
        createdAt: now,
        updatedAt: now,
      })
    },
    [clientId]
  )

  // const createClientDeviceByRedirect = useCallback(
  //   (input: ClientDeviceInput) => {
  //     const [id, setId] = useState<string | undefined>(undefined)
  //     const [loading, setLoading] = useState(true)
  //     const [error, setError] = useState<Error | undefined>(undefined)
  //     const abortController = new AbortController()

  //     const handleRegister = useCallback(async () => {
  //       try {
  //         await registerClientDevice(clientId, input, setId, setLoading, abortController)
  //       } catch (e) {
  //         setError(new Error(`error: ${e}`))
  //         setLoading(false)
  //         return
  //       }
  //     }, [setId, setLoading, setError, registerClientDevice, input, abortController])

  //     useEffect(() => {
  //       handleRegister()
  //       return () => {
  //         abortController.abort()
  //       }
  //     }, [handleRegister, abortController])

  //     return { id, loading, error }
  //   },
  //   [clientId]
  // )

  const updateIsTurnedOff = useCallback(
    async (id: string, isTurnedOff: boolean) => {
      const ref = doc(firestore, `clients/${clientId}/clientDevices/${id}`)
      await updateDoc(ref, { isTurnedOff, updatedAt: serverTimestamp() })
    },
    [clientId]
  )

  return { listClientDevicesWithDevice, createClientDevice, updateIsTurnedOff }
}

const sliceByNum = <T>(array: T[], num: number): T[][] => {
  if (num <= 0) return [[]]
  return array.reduce((acc: T[][], c, i: number) => (i % num ? acc : [...acc, ...[array.slice(i, i + num)]]), [])
}

// const registerClientDevice = async (
//   clientId: string,
//   input: ClientDeviceInput,
//   setId: (id: string) => void,
//   setLoading: (loading: boolean) => void,
//   abortController: AbortController
// ) => {
//   if (abortController.signal.aborted) {
//     return
//   }

//   // const deviceDocRef = doc(firestore, `devices/${input.deviceId}`).withConverter(deviceConverter)
//   // const deviceDoc = await getDoc(deviceDocRef)

//   // if (!deviceDoc.exists()) {
//   //   setError(new Error(`no such device: ${input.deviceId}`))
//   //   setLoading(false)
//   //   return
//   // }

//   const clientDeviceDocRef = doc(firestore, `clients/${clientId}/clientDevices/${input.deviceId}`)
//   const clientDeviceDoc = await getDoc(clientDeviceDocRef)
//   if (clientDeviceDoc.exists()) {
//     setId(clientDeviceDocRef.id)
//     setLoading(false)
//     return
//   }

//   const now = serverTimestamp()
//   await setDoc(clientDeviceDocRef, {
//     deviceId: input.deviceId,
//     isTurnedOff: input.isTurnedOff === undefined ? false : input.isTurnedOff,
//     createdAt: now,
//     updatedAt: now,
//   })

//   setId(clientDeviceDocRef.id)
//   setLoading(false)
// }
