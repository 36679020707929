import { ThemeProvider } from '@mui/material'
import * as Sentry from '@sentry/react'
// import { Elements } from '@stripe/react-stripe-js'
// import { loadStripe } from '@stripe/stripe-js'
import { Toaster } from 'react-hot-toast'
import 'src/App.css'
import { AuthProvider } from 'src/components/providers/AuthProvider'
import { RootErrorFallback } from 'src/components/RootErrorFallback'
import { RouteConfig } from 'src/components/routes/RouteConfig'
import { createTheme, ThemeName } from 'src/theme'

const theme = createTheme(ThemeName.Light)
// const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY)

function App() {
  return (
    <>
      <Sentry.ErrorBoundary fallback={RootErrorFallback}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            {/* <Elements stripe={stripePromise}> */}
            <Toaster position="bottom-left" />
            <RouteConfig />
            {/* </Elements> */}
          </AuthProvider>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </>
  )
}

export default App
