import { colors } from '@mui/material'

export const palette = {
  type: 'light',
  // primary: {
  //   main: colors.green[500],
  //   dark: colors.green[900],
  //   light: colors.green[300],
  //   contrastText: colors.common.white,
  // },
  // primary: {
  //   main: '#ff8c62',
  //   dark: '#ff4e0d',
  //   light: '##ffb295', // #F9DFD5
  //   contrastText: colors.common.white,
  // },
  primary: {
    main: '#FFAB26',
    dark: '#FF990A',
    light: '#FFC370',
    contrastText: colors.common.white,
  },
  secondary: {
    main: '#E91E63',
    dark: '#BE134D',
    light: '#F06191',
    contrastText: colors.common.white,
  },
  action: {
    active: '#686868',
    hover: '#F3F3F3',
    selected: '#5664D214',
    disabled: '#B5B5B5',
    disabledBackground: '#DCDCDC',
  },
  background: {
    default: colors.common.white,
    paper: colors.common.white,
    dark: '#F4F6F8',
  },
  text: {
    primary: '#333',
    secondary: 'rgba(66, 82, 110, 0.86)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  info: {
    main: '#2196F3',
    dark: '#0B79D0',
    light: '#64B6F7',
    contrastText: colors.common.white,
  },
  error: {
    main: '#F44336',
    dark: '#E31B0C',
    light: '#F88078',
    contrastText: colors.common.white,
  },
  warning: {
    main: '#FF9800', // #F9DB57
    dark: '#C77700', // #F6CA06
    light: '#FFB547', // #FCEDAA
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  success: {
    main: '#5EC84E',
    dark: '#23AC0E',
    light: '#A6E39D',
    contrastText: colors.common.white,
  },
  divider: '#E0E0E0',
} as const
