import { Sim } from '@maru44/huntre-utils/src/models/sim'
import { Box, Button, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material'
import { FormEvent, useCallback, useContext } from 'react'
import { toast } from 'react-hot-toast'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { useHttpsCallable } from 'src/utils/firebase'
import { capture } from 'src/utils/sentry'
import { KeyedMutator } from 'swr'

type Props = {
  open: boolean
  deviceId: string
  sim: Sim
  onClose: () => void
  mutate: KeyedMutator<Sim>
}

type Payload = {
  simId: string
  deviceId: string
  clientId: string
  stripeSubscriptionId: string
  cancelSubscriptionUpdate: boolean
}

export const CancelSubscriptionUpdate = ({ open, deviceId, sim, onClose, mutate }: Props) => {
  const { client } = useContext(ClientContext)

  const [call, isCalling, callError] = useHttpsCallable<Payload>('cancelSimSubscriptionUpdate')

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      try {
        e.preventDefault()
        const input: Payload = {
          clientId: client?.id ?? '',
          deviceId,
          simId: sim.id,
          stripeSubscriptionId: sim.clientSubscriptionId ?? '',
          cancelSubscriptionUpdate: !sim.cancelSubscriptionUpdate,
        }
        await call(input)
        await mutate()
        onClose()
      } catch (e) {
        capture(e)
        toast.error('通信の更新設定に失敗しました')
      }
    },
    [client?.id, deviceId, sim.id, call, onClose, mutate]
  )

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>通信の更新のキャンセル{sim.cancelSubscriptionUpdate && 'の取り消し'}</DialogTitle>
      <DialogContent>
        <Stack component="form" spacing={2} onSubmit={handleSubmit}>
          <Box mt={2} display="flex" gap={4} alignItems="center">
            <Button variant="outlined" onClick={onClose}>
              閉じる
            </Button>
            <Button variant="contained" type="submit" disabled={isCalling}>
              {sim.cancelSubscriptionUpdate ? 'キャンセルを取り消す' : 'キャンセルする'}
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
