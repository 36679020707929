import { Chip, Paper, Popover } from '@mui/material'
import { MouseEvent, ReactNode, useCallback, useState } from 'react'

type Props = {
  children: ReactNode
}

export const HelpPopover = ({ children }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handlePopoverOpen = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      setAnchorEl(e.currentTarget)
    },
    [setAnchorEl]
  )
  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])
  const open = Boolean(anchorEl)

  return (
    <>
      <Chip
        label="?"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{ cursor: 'pointer' }}
      />
      <Popover
        id="turnOffInfo"
        open={open}
        sx={{
          pointerEvents: 'none',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Paper sx={{ p: 1 }}>{children}</Paper>
      </Popover>
    </>
  )
}
